import React, { useState } from "react";
import './ReadMore.css'; // Import your CSS file

const ReadMore = ({ children, maxLength = 100 }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => setIsReadMore(!isReadMore);

    const textStyle = {
        marginTop: '0px',
        fontSize: '0.5rem'/* Increased font size */

      };

    return (
    <p className="read-more-text" styles = {textStyle}>
        {isReadMore ? text.slice(0, maxLength) : text}
        {text.length > maxLength && (
            <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? "Read more" : "Show less"}
            </span>
        )}
    </p>
    );
};

export default ReadMore;
