import React from "react";
import { Button, Container, FloatingLabel, Form, Stack } from "react-bootstrap";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";
import { Link } from "react-router-dom";

const EmailEntryForm = ({ onSubmit }) => {
    const [email, setEmail] = React.useState("");
    const [validated, setValidated] = React.useState(false);

    async function handleSubmit(e) {
        e.preventDefault();
        if (e.currentTarget.checkValidity()) {
            try {
                await axiosInterceptor.post("/request_password_reset/", {
                    email: email,
                    tp: "consumer",
                });
                onSubmit(email);
            } catch (err) {
                if (
                    err.response.status === 404 &&
                    err.response.data.error ===
                        "User with that email not found."
                ) {
                    // Means that a user for that email was not found — clear the field and alert.
                    setEmail("");
                    alert("No user found with that email.");
                } else {
                    console.error(err.response);
                    alert(
                        "An unknown error occurred — please try again later."
                    );
                }
            }
        } else if (!validated) setValidated(true);
    }

    return (
        <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="max-w-2xl mx-auto flex flex-col gap-y-2 w-[370px] h-[80vh] justify-center -mt-4"
        >
            <h2 className="text-2xl !font-normal text-left mb-3.5">
                Forgot Password
            </h2>
            <FloatingLabel label="Email">
                <Form.Control
                    name="email"
                    size="sm"
                    type="email"
                    placeholder=" "
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </FloatingLabel>
            <Button
                className="bg-[#1B4E99] text-white focus:bg-[#1B4E99] hover:bg-[#1B4E99] border-[#1B4E99] hover:border-[#1B4E99] font-medium text-sm px-4 py-2 rounded-lg shadow-sm mt-2.5"
                type="submit"
            >
                Login
            </Button>
            <p className="text-center text-sm !font-normal mt-1.5">
                <span className="text-black">Remember your password? </span>
                <Link
                    className="text-sm text-[#1B4E99] no-underline hover:text-[#1B4E99] hover:underline"
                    to={"../login"}
                >
                    Login
                </Link>
            </p>
        </Form>
    );
};

const EmailSentMessage = () => {
    return (
        <div className="max-w-2xl mx-auto flex flex-col gap-y-2 w-[370px] h-[80vh] justify-center -mt-4 text-center">
            <h2 className="text-2xl !font-normal mb-3.5">Forgot Password</h2>
            <p className="text-black font-light">
                A magic password reset link has been sent to your email.
            </p>
            <Button
                className="text-[#1B4E99] bg-white border-2 focus:text-[#1B4E99] hover:text-[#1B4E99] border-[#1B4E99] hover:border-[#1B4E99] font-medium text-sm px-4 py-2 rounded-md shadow-sm mt-2.5"
                href="/account/login"
                as={Link}
                onClick={() => {
                    window.location.href = "/account/login";
                }}
            >
                Back to Login
            </Button>
        </div>
    );
};

function RecoverForm(props) {
    const [step, setStep] = React.useState(0);
    const [display, setDisplay] = React.useState(null);
    const nextStep = () => setStep(step + 1);

    React.useEffect(() => {
        switch (step) {
            case 0:
                setDisplay(<EmailEntryForm onSubmit={nextStep} />);
                break;
            case 1:
                setDisplay(<EmailSentMessage />);
                break;
            default:
                setDisplay(null);
        }
    }, [step]);

    return (
        <Container style={{ maxWidth: 500, padding: 10 }}>{display}</Container>
    );
}

export default RecoverForm;
