import React, { useState, useEffect } from "react";
import CoursePath from "./CoursePath";
import "./Home.css";
import { ReactFlowProvider } from "react-flow-renderer";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";
import { getJsonCourseData } from "../../components/fileUtils.js";
import ScreenSpinner from "../../components/ScreenSpinner.jsx";
import { Dropdown } from "react-bootstrap";

const CourseRecommender = () => {
    const [courses, setCourses] = useState([]);
    const [masteredCourses, setMasteredCourses] = useState([]);
    const [inProgressCourses, setInProgressCourses] = useState([]);
    const [recommendations, setRecommendations] = useState([]);
    const [needMorePrerequisites, setNeedMorePrerequisites] = useState([]);
    const [coursesProgress, setCoursesProgress] = useState([]);
    const [selectedCourseTypes, setSelectedCourseTypes] = useState({});
    const [edges, setEdges] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const loadData = async () => {
        const jsonDataArray = await getJsonCourseData();
        const loadedCourses = jsonDataArray.map((jsonData) => ({
            id: jsonData.courseName.replace(/ /g, "-"),
            course: jsonData.courseName,
            courseNumber: jsonData.courseNumber || 0,
            courseType: jsonData.courseType,
            prereqs: jsonData.prereqs,
        }));
        setCourses(loadedCourses);
        const courseTypes = [
            ...new Set(loadedCourses.map((course) => course.courseType)),
        ];

        // Load saved course types from the backend
        const user_id = localStorage.getItem("user_id");
        try {
            const response = await axiosInterceptor.get(
                `/get_saved_course_types_for_user/${user_id}/`
            );
            const savedTypes = response.data.saved_course_types;
            const selectedTypes = courseTypes.reduce(
                (acc, type) => ({ ...acc, [type]: savedTypes.includes(type) }),
                {}
            );
            if (Object.values(selectedTypes).every((value) => !value)) {
                setSelectedCourseTypes(
                    courseTypes.reduce(
                        (acc, type) => ({ ...acc, [type]: true }),
                        {}
                    )
                );
            } else {
                setSelectedCourseTypes(selectedTypes);
            }
        } catch (error) {
            console.error("Error loading saved course types:", error);
            const selectedTypes = courseTypes.reduce(
                (acc, type) => ({ ...acc, [type]: true }),
                {}
            );
            setSelectedCourseTypes(selectedTypes);
        }

        return loadedCourses;
    };

    const getProgressData = async () => {
        const user_id = localStorage.getItem("user_id");
        const response = await axiosInterceptor.get(
            `/course-progress/${user_id}/`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const progressData = response.data;
        const masteredCoursesData = progressData.filter(
            (courseEntry) => courseEntry.progress >= 1.0
        );
        const inProgressCoursesData = progressData.filter(
            (courseEntry) =>
                courseEntry.progress > 0.0 && courseEntry.progress < 1.0
        );

        setInProgressCourses(inProgressCoursesData);
        setMasteredCourses(masteredCoursesData);
        setCoursesProgress(progressData);

        // We also set coursesprogress all the courses that are not in coursesprogress
        const setOfCourseNumbersInCoursesProgress = new Set(
            progressData.map((course) => course.courseNumber)
        );
        const coursesNotInCoursesProgress = courses.filter(
            (course) =>
                !setOfCourseNumbersInCoursesProgress.has(course.courseNumber)
        );
        setCoursesProgress([...progressData, ...coursesNotInCoursesProgress]);
        return progressData;
    };

    useEffect(() => {
        const fetchAllData = async () => {
            const loadedCourses = await loadData();
            const progressData = await getProgressData();
            await handleEvaluatePrerequisites(loadedCourses, progressData);
            setDataLoaded(true);
        };

        fetchAllData();
    }, [refetch]);

    const handleCourseTypeChange = async (type) => {
        const newSelectedTypes = {
            ...selectedCourseTypes,
            [type]: !selectedCourseTypes[type],
        };
        setSelectedCourseTypes(newSelectedTypes);

        // Save the updated course types to the backend
        const user_id = localStorage.getItem("user_id");
        const savedTypes = Object.entries(newSelectedTypes)
            .filter(([, isSelected]) => isSelected)
            .map(([courseType]) => courseType);

        try {
            await axiosInterceptor.post(
                `/set_saved_course_types_for_user/${user_id}/`,
                {
                    saved_course_types: savedTypes,
                }
            );
        } catch (error) {
            console.error("Error saving course types:", error);
        }
    };

    const filterCoursesByType = (coursesList) => {
        const output = coursesList.filter(
            (course) => selectedCourseTypes[course.courseType]
        );
        return output;
    };

    const handleEvaluatePrerequisites = async (loadedCourses, progressData) => {
        try {
            const masteredCourseNumbers = progressData
                .filter((course) => course.progress >= 1.0)
                .map((course) => course.courseNumber);

            const response = await axiosInterceptor.post(
                `/api/evaluate-prerequisites`,
                { masteredCourses: masteredCourseNumbers },
                { headers: { "Content-Type": "application/json" } }
            );

            const data = response.data;
            const updatedRecommendations = data.recommendations
                .map((courseNum) =>
                    loadedCourses.find(
                        (course) => course.courseNumber === courseNum
                    )
                )
                .filter((course) => course != null);

            setRecommendations(updatedRecommendations);

            const updatedNeedMorePrerequisites = loadedCourses.filter(
                (course) =>
                    !masteredCourseNumbers.includes(course.courseNumber) &&
                    !updatedRecommendations.some(
                        (rec) => rec.courseNumber === course.courseNumber
                    )
            );
            setNeedMorePrerequisites(updatedNeedMorePrerequisites);
        } catch (error) {
            console.error("Error in fetching:", error);
        }
    };

    useEffect(() => {
        if (dataLoaded && courses.length > 0) {
            const filteredCourses = filterCoursesByType(courses);
            const courseMap = courses.reduce((acc, course) => {
                acc[course.courseNumber] = course.id;
                return acc;
            }, {});
            createNodes(courseMap);
            createEdges(courseMap);
        }
    }, [
        dataLoaded,
        courses,
        recommendations,
        needMorePrerequisites,
        masteredCourses,
        inProgressCourses,
        selectedCourseTypes,
    ]);

    const getPrerequisiteNames = (prereqs) => {
        if (prereqs.length === 0) {
            return "No Prerequisites";
        }

        return prereqs
            .map((prereqId) => {
                const prereqCourse = courses.find(
                    (course) => course.courseNumber === prereqId
                );
                return prereqCourse ? prereqCourse.course : "Unknown";
            })
            .join(", ");
    };

    const setEmptyCourseProgress = async () => {
        try {
            const user_id = localStorage.getItem("user_id");
            const courseSet = courses.map(
                (course) =>
                    // course.name === "Topics in Math with Applications in Finance" ||
                    // (course.name ?? course.id ?? "").includes("C++")
                    //     ? course.courseNumber + "-2"
                    // :
                    course.courseNumber
            );
            const courseProgressSet = new Set(
                coursesProgress.map((course) => course.courseNumber)
            );
            const filteredSet = courseSet.filter((course) => {
                const hasCourseId = courseProgressSet.has(course);
                return !hasCourseId;
            });
            let postBody = [];
            filteredSet.forEach((course) => {
                postBody.push({
                    courseNumber: course,
                    progress: 0.0,
                });
            });
            // const response = await axiosInterceptor.patch(
            //     `course-progress/update/${user_id}/`,
            //     {
            //         updates: postBody,
            //     },
            //     {
            //         headers: {
            //             "Content-Type": "application/json",
            //         },
            //     }
            // );
            return {};
        } catch (error) {
            console.error("Error getting course progress:", error);
        }
    };

    useEffect(() => {
        loadData().then(() => getProgressData());
    }, [refetch]);

    useEffect(() => {
        if (coursesProgress.length > 0 && courses.length > 0) {
            setEmptyCourseProgress();
        }
    }, [refetch, coursesProgress]);

    const refetchData = () => {
        setRefetch(!refetch);
    };

    useEffect(() => {
        if (courses.length > 0) {
            const courseMap = courses.reduce((acc, course) => {
                acc[course.courseNumber] = course.id;
                return acc;
            }, {});
            createNodes(courseMap);
            createEdges(courseMap);
        }
    }, [
        refetch,
        courses,
        recommendations,
        needMorePrerequisites,
        masteredCourses,
        inProgressCourses,
    ]);

    const chooseColor = (courseId) => {
        if (
            masteredCourses.some((course) => course.courseNumber === courseId)
        ) {
            return "#d1e7ff";
        } else if (
            inProgressCourses.some((course) => course.courseNumber === courseId)
        ) {
            return "#fff3cd";
        } else if (
            recommendations.some((course) => course.courseNumber === courseId)
        ) {
            return "#CCD9AB";
        } else {
            // Need more prereqs.
            return "#f8d7da";
        }
    };

    const createNodes = (courseMap) => {
        const levels = {};
        const courseLevels = [];
        const filteredCourses = filterCoursesByType(courses);

        // Helper function to get the level of a course
        const getCourseLevel = (course) => {
            if (levels[course.courseNumber] !== undefined) {
                return levels[course.courseNumber];
            }

            if (!course.prereqs || course.prereqs.length === 0) {
                levels[course.courseNumber] = 0;
                return 0;
            }

            const prereqLevels = course.prereqs.map((prereq) => {
                const prereqCourse = courses.find(
                    (c) => c.courseNumber === prereq
                );
                return prereqCourse ? getCourseLevel(prereqCourse) : -1;
            });

            const level = Math.max(...prereqLevels) + 1;
            levels[course.courseNumber] = level;
            return level;
        };

        // Assign levels to all filtered courses
        filteredCourses.forEach((course) => {
            const level = getCourseLevel(course);
            courseLevels[level] = courseLevels[level] || [];
            courseLevels[level].push(course);
        });

        let temp_nodes = [];
        const xSpacing = 200;
        const ySpacing = 120;
        const nodeWidth = 180;
        const nodeHeight = 40;

        courseLevels.forEach((levelCourses, level) => {
            if (!levelCourses) return; // Skip empty levels
            const levelWidth =
                levelCourses.length * (nodeWidth + xSpacing) - xSpacing;
            const startX = -levelWidth / 2;

            levelCourses.forEach((course, index) => {
                let id = course.courseNumber;
                // if (id.includes("096") && course.id.includes("++")) {
                //     id = id + "-2";
                // }
                const position = {
                    x: startX + index * (nodeWidth + xSpacing),
                    y: level * (nodeHeight + ySpacing),
                };
                const color = chooseColor(id);
                const style = {
                    backgroundColor: color,
                    borderRadius: "10px",
                    width: nodeWidth,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "12px",
                    border: "1px solid #ccc",
                };

                temp_nodes.push({
                    id: course.id,
                    data: { label: course.id },
                    position: position,
                    style: style,
                });
            });
        });

        setNodes(temp_nodes);
    };

    const createEdges = (courseMap) => {
        let temp_edges = [];
        const filteredCourses = filterCoursesByType(courses);
        filteredCourses.forEach((course) => {
            if (course.prereqs && course.prereqs.length > 0) {
                course.prereqs.forEach((prereq) => {
                    const prereqCourse = courses.find(
                        (c) => c.courseNumber === prereq
                    );
                    if (
                        prereqCourse &&
                        courseMap[prereq] &&
                        filteredCourses.includes(prereqCourse)
                    ) {
                        temp_edges.push({
                            source: courseMap[prereq],
                            target: course.id,
                            id: `${courseMap[prereq]}-${course.id}`,
                            type: "smoothstep",
                            animated: true,
                            style: { stroke: "#000" },
                        });
                    }
                });
            }
        });
        setEdges(temp_edges);
    };

    return !dataLoaded ? (
        <ScreenSpinner />
    ) : (
        <div className="h-full mb-20">
            <div className="flex flex-col gap-y-2">
                <h1 className="text-3xl font-medium text-left">Course Map</h1>
                <div className="text-xs text-gray-500 lg:w-5/12">
                    Based on your preference form, we recommend the following
                    course map to stand out as a candidate for top companies in
                    your desired field.
                </div>
            </div>
            <div className="h-full">
                <div className="h-full">
                    <div
                        className="h-full mt-10 lg:mt-6 relative"
                    >
                        <Dropdown className="absolute top-[16px] left-[16px] z-10">
                            <Dropdown.Toggle className="rounded-lg shadow-sm active:shadow-sm bg-white border text-black font-medium !text-xs">
                                Course Type
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="mt-2 rounded-lg shadow-sm cursor-pointer !text-base !font-normal">
                                {Object.keys(selectedCourseTypes).map(
                                    (type) => (
                                        <Dropdown.Item
                                            className="active:!bg-transparent focus:!bg-transparent active:!text-black focus:!text-black"
                                            key={type}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                handleCourseTypeChange(type);
                                            }}
                                        >
                                            <div className="flex items-center gap-x-2 flex-row">
                                                <input
                                                    checked={
                                                        selectedCourseTypes[
                                                            type
                                                        ]
                                                    }
                                                    type="checkbox"
                                                    className="accent-[#1B4E99] w-4 h-4 rounded"
                                                    style={{
                                                        marginLeft: "-2px",
                                                        marginTop: "-2px",
                                                        fontSize: "3px",
                                                    }}
                                                />
                                                {type}
                                            </div>
                                        </Dropdown.Item>
                                    )
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="h-full">
                            <div className="relative w-full h-full bg-white rounded-lg course-card">
                                <ReactFlowProvider>
                                    <CoursePath
                                        nodes={nodes}
                                        edges={edges}
                                        courses={filterCoursesByType(courses)}
                                        coursesProgress={coursesProgress}
                                        reFetchData={refetchData}
                                    />
                                </ReactFlowProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseRecommender;
