import React from "react";
import Hero from "../../components/Hero.jsx";
import Faq from "react-faq-component";

export default function FAQ({ limit = 20, showHeader = true }) {
    let data = {
        rows: [
            {
                title: <h5>What is AlgoLink and how can it help advance my career?</h5>,
                content: (
                    <h6 style={{ color: "#333" }}>
                        AlgoLink is an innovative platform designed to revolutionize your job search and professional development. We connect technical talent with employers through advanced algorithms, offering a suite of features tailored to enhance every aspect of your career journey. By creating a comprehensive profile, engaging with our Test Center, exploring our extensive course offerings, utilizing our intuitive Job Finder, tracking your recruitment progress, and communicating directly with recruiters through Messages, you gain unprecedented control and insight into your career path. Much like a dating app matches you with compatible partners, AlgoLink intelligently matches you with the right referrers and recruiters who align with your skills, goals, and aspirations, providing them with the tools they need to understand you fully.
                    </h6>
                ),
            },
            {
                title: <h5>How does the Profile feature enhance my job search?</h5>,
                content: (
                    <h6 style={{ color: "#333" }}>
                        Your Profile serves as a dynamic showcase of your professional identity, encompassing your work experience, education, personality traits, and career interests. By thoroughly detailing these aspects, you enable our sophisticated algorithms to match you with job opportunities and recruiters that align closely with your background and aspirations. This personalized matching process increases the likelihood of finding roles that are not only a fit for your skills but also for your long-term career goals. Additionally, recruiters gain valuable insights into your unique qualifications and motivations, facilitating more meaningful connections.
                    </h6>
                ),
            },
            {
                title: <h5>What is the Test Center and why is it important?</h5>,
                content: (
                    <h6 style={{ color: "#333" }}>
                        The Test Center is a comprehensive assessment hub that evaluates your abilities across three critical dimensions: Behavioral, Background, and Technical. By participating in these assessments, you gain a deeper understanding of your strengths, weaknesses, and areas for improvement. This self-awareness is invaluable for personal development and interview preparation. Moreover, your assessment results provide recruiters with quantifiable data on your competencies, enabling them to make informed decisions and increasing your visibility to potential employers who are seeking candidates with your specific skill set.
                    </h6>
                ),
            },
            {
                title: <h5>How do the Courses contribute to my professional growth?</h5>,
                content: (
                    <h6 style={{ color: "#333" }}>
                        Our platform offers an extensive library of technical studying resources and curated career paths designed to enhance your expertise and keep you competitive in the rapidly evolving tech industry. Whether you're looking to acquire new skills or deepen existing ones, our courses cover a wide array of topics tailored to your interests and career objectives. Engaging with our curriculum not only boosts your knowledge but also signals to recruiters and employers your commitment to continuous learning and professional excellence.
                    </h6>
                ),
            },
            {
                title: <h5>What makes the Job Finder feature unique and effective?</h5>,
                content: (
                    <h6 style={{ color: "#333" }}>
                        The Job Finder is an intuitive tool that empowers you to search and filter for your ideal job based on various criteria such as role, industry, location, and more. By indicating which jobs you're interested in, you help our system further refine your experience, ensuring that you receive recommendations that align with your preferences and qualifications. This targeted approach streamlines your job search, saving you time and connecting you with opportunities that are most relevant to your career aspirations. Additionally, our platform facilitates matches with recruiters who are actively seeking candidates like you, much like how dating apps connect you with compatible partners.
                    </h6>
                ),
            },
            {
                title: <h5>How does Recruitment Progress Tracking keep me organized?</h5>,
                content: (
                    <h6 style={{ color: "#333" }}>
                        Recruitment Progress Tracking is a powerful feature that allows you to manage and monitor all your job applications and interview stages in one centralized location. You can easily view updates on your application status, schedule interviews, and receive feedback from employers. The ability to search and filter your applications by stage helps you prioritize your efforts and stay on top of critical deadlines. This level of organization ensures that you remain proactive and engaged throughout the recruitment process, enhancing your chances of success.
                    </h6>
                ),
            },
            {
                title: <h5>What benefits does the Messages feature offer?</h5>,
                content: (
                    <h6 style={{ color: "#333" }}>
                        The Messages feature facilitates direct communication with referrers and recruiters, fostering meaningful professional relationships. By staying in close contact throughout the recruitment process, you can ask questions, receive timely updates, and gain valuable insights into the company and role you're pursuing. This open line of communication not only enhances your understanding of potential opportunities but also allows recruiters to better comprehend your skills, goals, and aspirations. By providing them with comprehensive information, they can more effectively advocate for you within their organizations, similar to how dating apps enable deeper connections through direct interaction.
                    </h6>
                ),
            },
        ],
    };

  const styles = {
    titleTextColor: "#1B4E99",
    titleTextSize: "48px",
    rowTitleColor: "#1B4E99",
    rowTitleTextSize: "20px",
    rowContentColor: "gray",
    rowContentFontWeight: "normal",
    rowContentTextSize: "16px",
    arrowColor: "black",
    transitionDuration: "0.75s",
    timingFunc: "ease",
  };

  const config = {
    expandIcon: "+",
    collapseIcon: "-",
  };

  return (
    <main>
      {showHeader && <Hero content="Frequently Asked Questions" />}

      <div
        style={{
          marginLeft: "50px",
          marginRight: "50px",
          marginTop: showHeader ? "50px" : "",
          marginBottom: "50px",
        }}
      >
        <Faq data={data} styles={styles} config={config} />
      </div>
    </main>
  );
}

// import React from "react";
// import Hero from "../../components/Hero.jsx";
// import Faq from "react-faq-component";

// export default function FAQ(props) {


//     if (props.limit) {
//         data.rows = data.rows.slice(0, props.limit);
//     }

//     const styles = {
//         titleTextColor: "#1B4E99",
//         titleTextSize: "48px",
//         rowTitleColor: "#1B4E99",
//         rowTitleTextSize: "20px",
//         rowContentColor: "gray",
//         rowContentFontWeight: "normal",
//         rowContentTextSize: "16px",
//         arrowColor: "black",
//         transitionDuration: "0.75s",
//         timingFunc: "ease",
//       };

//     // Extend style with props.customStyle if exists.
//     if (props.customStyle) {
//         styles = { ...styles, ...props.customStyle };
//     }

//     console.log(styles);

//     const config = {
//         expandIcon: "+",
//         collapseIcon: "-",
//     };

//     return props.showHeader ?? true ? (
//         <main>
//             <Hero content="Frequently Asked Questions" />

//             <div
//                 style={{
//                     marginLeft: "50px",
//                     marginRight: "50px",
//                     marginTop: "50px",
//                     marginBottom: "50px",
//                 }}
//             >
//                 <Faq data={data} styles={styles} config={config} />
//             </div>
//         </main>
//     ) : (
//         <Faq data={data} styles={styles} config={config} />
//     );
// }
