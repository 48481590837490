// fetchUtils.js
import { API_BASE_URL } from "../constants.js";
import axiosInterceptor from "../utils/axiosInterceptor.ts";

/**
 * Get a list of file names in a directory.
 * @param {string} directoryPath - The path to the directory.
 * @returns {string[]} - An array of file names in the directory.
 */

export async function getFilesFromDirectory(directoryPath) {
    // async
    try {
        // Make an HTTP GET request to the Django backend
        const response = await axiosInterceptor.get(
            `/list_files/${directoryPath}`
        );
        if (response.status !== 200) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.data;
        return data.file_urls;
    } catch (error) {
        console.error(`Error fetching files from directory: ${error.message}`);
        return [];
    }
}

// NOTE: if fetching from the base api url, update to use axiosInterceptor.
export async function fetchPngFromUrl(url) {
    try {
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Retrieving the image as a Blob
        const imageBlob = await response.blob();
        return imageBlob;
    } catch (error) {
        console.error("Error fetching PNG:", error);
        throw error; // Rethrow the error to propagate it
    }
}

// const BASE_URL = "https://ai-academy-storage-f951b86a124629-staging.s3.amazonaws.com/";

// const DEBOUNCE_NUM = 200;
// export const getJsonUrls = async () => { // async
//   try {

//     // const response = await fetch("s3://ai-academy-storage-f951b86a124629-staging/public/static/generated/");
//     const response = await axiosInterceptor.get(`/list_files/generated/`);
//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }
//     const jsonFiles = await response.json();
//     const jsonUrls = jsonFiles.file_urls.map((file) => BASE_URL + file.replace('public/static/', '') + '/');
//     return jsonUrls;
//   } catch (error) {
//     console.error("Error fetching JSON files:", error);
//     throw error; // Propagate the error for handling in the component
//   }
// };

// export const getJsonCourseData = async () => {
//   try {
//     // Step 1: Fetch the list of file paths
//     const listResponse = await axiosInterceptor.get(`/list_files/generated`);
//     if (!listResponse.ok) {
//       throw new Error(`HTTP error! Status: ${listResponse.status}`);
//     }
//     const jsonResponse = await listResponse.json();
//     console.log(jsonResponse)
//     const fileList = jsonResponse.file_urls.map(file => `https://ai-academy-storage-f951b86a124629-staging.s3.amazonaws.com/${file.replace('public/static/', '')}`);

//     // const fileList = jsonResponse.file_urls
//     // Assuming fileList is an array of file paths
//     const filePaths = fileList.map(file => API_BASE_URL + file.replace('public/static/', '')); // Adjust based on actual response structure
//     // i want to run a fetch to each of these file variables s3 bucket instead of serve_course_jsons:
//     // const data = await fetch(`https://ai-academy-storage-f951b86a124629-staging.s3.amazonaws.com/${file}`)

//     console.log(filePaths)
//     // Step 2: Use the modified serve_json endpoint to get presigned URLs for multiple files
//     const serveJsonResponse = await axiosInterceptor.get(`/api/serve_course_jsons/`, {
//       method: 'GET', // Assuming the modified endpoint accepts a POST request
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });
//     console.log(serveJsonResponse)
//     if (!serveJsonResponse.ok) {
//       throw new Error(`HTTP error! Status: ${serveJsonResponse.status}`);
//     }
//     const presignedUrlsData = await serveJsonResponse.json();
//     console.log(presignedUrlsData)

//     // Assuming the backend returns an array of objects each with a presigned_url
//     // const jsonUrls = presignedUrlsData.map(item => API_BASE_URL + item.presigned_url);
//     // console.log(jsonUrls)

//     return presignedUrlsData;
//   } catch (error) {
//     console.error("Error fetching JSON files:", error);
//     throw error;
//   }
// };

// Fetch JSON data from each file URL directly from S3
export const fetchJsonData = async (url) => {
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
};

export const getJsonCourseData = async () => {
    try {
        const response = await axiosInterceptor.get("/api/serve_course_jsons/");

        return await response.data;
    } catch (error) {
        console.error("Error fetching JSON files:", error);
        throw error;
    }
};

export const getJsonUrl = async () => {
    try {
        // Step 1: Fetch the list of file paths
        const listResponse = await axiosInterceptor.get(
            `/list_files/generated`
        );
        if (!listResponse.ok) {
            throw new Error(`HTTP error! Status: ${listResponse.status}`);
        }
        const jsonResponse = await listResponse.json();
        console.log(jsonResponse);
        const fileList = jsonResponse.file_urls;

        // Assuming fileList is an array of file paths
        const filePaths = fileList.map(
            (file) =>
                API_BASE_URL +
                "/" +
                file.replace("public/static/", "serve_json")
        ); // Adjust based on actual response structure
        console.log(filePaths);
        // Step 2: Use the modified serve_json endpoint to get presigned URLs for multiple files
        const serveJsonResponse = await axiosInterceptor.get(`/serve_json/`, {
            file_paths: filePaths,
        });
        if (!serveJsonResponse.ok) {
            throw new Error(`HTTP error! Status: ${serveJsonResponse.status}`);
        }
        const presignedUrlsData = await serveJsonResponse.json();

        // Assuming the backend returns an array of objects each with a presigned_url
        const jsonUrls = presignedUrlsData.map((item) => item.presigned_url);

        return jsonUrls;
    } catch (error) {
        console.error("Error fetching JSON files:", error);
        throw error;
    }
};

// export const debouncedGetJsonUrls = debounce(getJsonUrls, DEBOUNCE_NUM);

// Sleep function to introduce delay
function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export const fetchDataFromUrls = async (jsonUrls) => {
    const jsonDataArray = []; // To store the responses
    console.log("fetchDataFromUrls");
    console.log(jsonUrls);

    for (const url of jsonUrls) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const jsonData = await response.json();
            jsonDataArray.push(jsonData);

            // Wait for a bit before the next request (e.g., 1000 milliseconds delay)
            await sleep(1000);
        } catch (error) {
            console.error("Error fetching JSON data:", error);
            throw error; // Propagate the error for handling in the component
        }
    }

    return jsonDataArray;
};

// export const fetchDataFromUrls = async (jsonUrls) => { // async
//   try {
//     const promises = jsonUrls.map(async (url) => {
//       const response = await fetch(url);
//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }

//       return response.json();
//     });

//     const jsonDataArray = await Promise.all(promises);
//     return jsonDataArray;
//   } catch (error) {
//     console.error("Error fetching JSON data:", error);
//     throw error; // Propagate the error for handling in the component
//   }
// };

// export const debouncedFetchDataFromUrls = debounce(fetchDataFromUrls, DEBOUNCE_NUM);

export async function fetchDataFromUrl(url, fileType) {
    // async
    try {
        const dataUrl = `/serve_${fileType}/${url}`;
        console.log(dataUrl);
        const response = await axiosInterceptor.get(dataUrl);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error("Error fetching JSON:", error);
        throw error; // Rethrow the error to propagate it
    }
}

// export const debouncedFetchDataFromUrl = debounce(fetchDataFromUrl, DEBOUNCE_NUM);
// export const debouncedGetFilesFromDirectory = debounce(getFilesFromDirectory, DEBOUNCE_NUM);

export async function getJsonFromUrl(apiUrl) {
    // async
    try {
        const response = await fetch(apiUrl);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error("Error fetching JSON:", error);
    }
}

// export const debouncedGetJsonFromUrl = debounce(getJsonFromUrl, DEBOUNCE_NUM);

export function findDirectoryForLesson(lessonId, data) {
    let totalItemsSeen = 0;
    let foundDirectory = null;
    let count = 1;
    for (const fileList of data.filesInNonEmptyDirectories) {
        if (
            lessonId >= totalItemsSeen &&
            lessonId < totalItemsSeen + fileList.length
        ) {
            const indexInList = lessonId - totalItemsSeen;
            fileList.sort((a, b) => {
                const numberA = parseInt(a.match(/(\d+)/)[0], 10);
                const numberB = parseInt(b.match(/(\d+)/)[0], 10);
                return numberA - numberB;
            });
            foundDirectory = fileList[indexInList];
            break;
        }
        totalItemsSeen += fileList.length;
        count += 1;
    }
    return { count, foundDirectory };
}
