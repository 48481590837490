import React from "react";
import AlgoLinkHalfGear from "../../assets/img/algolink-half-gear.svg";

function PendingAlgolinkVerification(props) {
    return (
        <div className="mx-auto container max-w-2xl h-full flex flex-col justify-center gap-y-6 relative">
            <img
                src={AlgoLinkHalfGear}
                alt="AlgoLink Half Gear"
                className="absolute top-20 -left-20 h-48 z-0 rotate-[170deg]"
            />
            <h2 className="text-3xl font-semibold">
                Thank you for signing up with AlgoLink!
            </h2>
            <div className="flex flex-col gap-y-6">
                {/* TODO: need to figure out where all the weird p-specificity defaults are coming from */}
                <p className="text-black text-base">
                    We’re excited about your interest in joining our platform.
                    You’ll receive a quick confirmation email within the hour to
                    confirm that we’ve received your application.
                </p>
                <p className="text-black text-base">
                    Please note that we’re currently in the beta testing phase,
                    so our application process may take a bit longer as we
                    carefully add new users. We appreciate your patience as we
                    work through this process, and we’ll keep you updated on
                    your application status.
                </p>
                <div className="flex flex-col gap-y-2 text-black">
                    <p className="font-semibold text-black text-base">
                        What to Expect Next:
                    </p>
                    <ol className="list-decimal pl-8">
                        <li className="list-disc">
                            <p className="text-black text-base">
                                {" "}
                                <strong className="font-medium">
                                    Check Your Email:
                                </strong>{" "}
                                Look out for an approval email from us within
                                the next few weeks.
                            </p>
                        </li>
                        <li className="list-disc">
                            <p className="text-black text-base">
                                <strong className="font-medium">
                                    Prepare to Get Started:
                                </strong>{" "}
                                Once approved, you’ll be able to complete a more
                                detailed profile and start utilizing our test
                                center to assess your skills.
                            </p>
                        </li>
                    </ol>
                </div>
                <p className="text-black text-base">
                    Thank you for your understanding and enthusiasm. We look
                    forward to potentially having you on board as we grow and
                    refine AlgoLink!
                </p>
                <p className="text-black text-end text-base font-medium">
                    — The AlgoLink Team
                </p>
            </div>
            <img
                src={AlgoLinkHalfGear}
                alt="AlgoLink Half Gear"
                className="absolute bottom-14 -right-6 h-36 z-0 rotate-[-10deg]"
            />
        </div>
    );
}

export default PendingAlgolinkVerification;
