import React, { useState, useEffect } from "react";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export default function FormInput({
  name,
  label,
  type,
  value,
  handler,
  required,
}) {
  return (
    <FloatingLabel label={label}>
      <Form.Control
        name={name}
        type={type}
        required={required ? true : false}
        value={value}
        onChange={handler}
      />
    </FloatingLabel>
  );
}

export function DropdownSelect({
  options,
  name,
  label,
  type,
  value,
  handler,
  required
}) {
  return (
    <FloatingLabel label={label}>
      <Form.Control
        as={"select"}
        name={name}
        type={type}
        value={value}
        onChange={handler}
        required={required ? true : false}
        >
        <option value = "option1">Option 1</option>
        </Form.Control>
    </FloatingLabel>
  );
};

export function DateInput({ pos, id, sd, ed, handler }) {
  const is_ed = (ed === null)
  const [checked, setChecked] = useState(is_ed);

  function handleCheck(e) {
    setChecked(!checked);
    if (e.target.checked) {
      handler({ target: { name: "end_date", value: "" } });
    }
  }
  
  return (
    <div>
      {pos ? (
        <Form.Check
          id={`date-${id}`}
          label={`This is my current position`}
          onChange={handleCheck}
          value={checked}
          type = "checkbox"
        />
      ) : (
        <></>
      )}
      <Row>
        <Form.Group controlId={`start-date-${id}`} as={Col}>
          <Form.Label className="m-0">Start Date</Form.Label>
          <Form.Control
            type="date"
            name="start_date"
            placeholder="Start Date"
            onChange={handler}
            value={sd}
          />
        </Form.Group>
        {!checked ? (
          <Form.Group controlId={`end-date-${id}`} as={Col}>
            <Form.Label className="m-0">End Date</Form.Label>
            <Form.Control
              type="date"
              name="end_date"
              placeholder="End Date"
              onChange={handler}
              value={ed}
            />
          </Form.Group>
        ) : null}
      </Row>
    </div>
  );
}
