import React, {
    useRef,
    useState,
    useCallback,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from "react";
import Webcam from "react-webcam";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";

const CameraRecorder = forwardRef(
    (
        {
            isBackground,
            isBehavioral,
            isTechnical,
            audio = true,
            ids,
            type,
            rounded = false,
            moreClasses = "",
        },
        ref
    ) => {
        const webcamRef = useRef(null);
        const mediaRecorderRef = useRef(null);
        const [capturing, setCapturing] = useState(false);
        const [recordedChunks, setRecordedChunks] = useState([]);
        const [isWebcamReady, setIsWebcamReady] = useState(false);
        const [isRecordingStopped, setIsRecordingStopped] = useState(false);
        const [isFlashing, setIsFlashing] = useState(true);

        const handleDataAvailable = useCallback(({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prevChunks) => {
                    const updatedChunks = [...prevChunks, data];
                    return updatedChunks;
                });
            }
        }, []);

        const startRecording = useCallback(() => {
            setRecordedChunks([]);
            if (
                webcamRef.current &&
                webcamRef.current.video &&
                webcamRef.current.video.srcObject
            ) {
                const stream = webcamRef.current.video.srcObject;
                mediaRecorderRef.current = new MediaRecorder(stream, {
                    mimeType: "video/webm",
                });
                mediaRecorderRef.current.ondataavailable = handleDataAvailable;
                mediaRecorderRef.current.start();
                setCapturing(true);
            } else {
                console.log("Webcam stream not available or not ready");
            }
        }, [handleDataAvailable]);

        const handleStopCaptureClick = useCallback(() => {
            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.stop();
                setCapturing(false);
                setIsRecordingStopped(true);
            } else {
                console.log("No media recorder to stop");
            }
        }, []);

        const handleUpload = useCallback(() => {
            if (recordedChunks.length > 0) {
                const blob = new Blob(recordedChunks, {
                    type: "video/webm",
                });

                const user_id = localStorage.getItem("user_id");
                const uploadURL = `file_upload/${user_id}/`;
                const csrfToken = document.cookie.match(
                    /csrftoken=([^;]+)/
                )?.[1];

                const formData = new FormData();
                formData.append("root_directory", "video");
                formData.append("file", blob, `ids=${ids.toString()}.webm`);
                formData.append("promptType", type);

                axiosInterceptor
                    .post(uploadURL, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "X-CSRFToken": csrfToken,
                        },
                    })
                    .then(async (uploadResponse) => {
                        console.log("Upload successful", uploadResponse.data);
                    })
                    .catch((error) => {
                        console.error(
                            "Error during file upload or processing: ",
                            error
                        );
                    });
            }
            // const url = URL.createObjectURL(blob);
            // const a = document.createElement("a");
            // document.body.appendChild(a);
            // a.style.display = "none";
            // a.href = url;
            // a.download = `ids=${ids.toString()}.webm`;
            // a.click();
            // window.URL.revokeObjectURL(url);
            else {
                console.log("No recorded chunks to upload");
            }
        }, [recordedChunks]);

        useEffect(() => {
            if (isRecordingStopped && recordedChunks.length > 0) {
                setIsRecordingStopped(false);
                handleUpload();
            }
        }, [isRecordingStopped, recordedChunks, handleUpload]);

        useEffect(() => {
            const checkWebcamReady = setInterval(() => {
                if (
                    webcamRef.current &&
                    webcamRef.current.video &&
                    webcamRef.current.video.srcObject
                ) {
                    setIsWebcamReady(true);
                    clearInterval(checkWebcamReady);
                }
            }, 100);

            return () => {
                clearInterval(checkWebcamReady);
                if (mediaRecorderRef.current) {
                    mediaRecorderRef.current.stop();
                }
            };
        }, []);

        useEffect(() => {
            if (isWebcamReady) {
                startRecording();
            }
        }, [isWebcamReady, startRecording]);

        useImperativeHandle(
            ref,
            () => ({
                stopAndDownload: handleStopCaptureClick,
            }),
            [handleStopCaptureClick]
        );

        useEffect(() => {
            let interval;
            if (capturing) {
                interval = setInterval(() => {
                    setIsFlashing((prev) => !prev);
                }, 500); // Toggle every 500ms
            }
            return () => {
                if (interval) clearInterval(interval);
            };
        }, [capturing]);

        const classNames = [
            "camera-container",
            isBackground && "background",
            isBehavioral && "behavioral",
            isTechnical && "technical",
            moreClasses,
        ]
            .filter(Boolean)
            .join(" ");

        return (
            <div
                className={
                    classNames +
                    (rounded ? " !rounded-full !overflow-hidden" : "")
                }
            >
                <Webcam className={"camera"} audio={audio} ref={webcamRef} />
                {capturing && (
                    <>
                        <div
                            className={`recording-indicator ${
                                isFlashing ? "visible" : ""
                            } ${isTechnical ? "!right-[4.65rem]" : ""}`}
                        >
                            <div className="recording-dot"></div>
                        </div>
                        {isTechnical && (
                            <span className="absolute top-[2.2rem] text-white right-[2.2rem] text-xs -translate-x-1/2 -translate-y-1/2">
                                REC
                            </span>
                        )}
                    </>
                )}
            </div>
        );
    }
);

export default CameraRecorder;
