// import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import express from 'express';

// Bootstrap
// import "bootstrap-icons/font/bootstrap-icons.css";

// AWS Amplify Config
// import { Amplify } from 'aws-amplify';
// import awsExports from './aws-exports';
// Amplify.configure(awsExports)
// import { Amplify } from 'aws-amplify';
//import awsExports from './aws-exports';
//Amplify.configure(awsExports)

ReactDOM.createRoot(document.getElementById("root")).render(<App />);

// const cors = require('cors')
// const express = require('express');

// const app = express()

// const corsOptions ={
//     origin:'http://localhost:3000',
//     credentials:true,            //access-control-allow-credentials:true
//     optionSuccessStatus:200
// }

// app.use(cors(corsOptions));
