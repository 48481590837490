import React from "react";
import ReactFlow, { useReactFlow, Background } from "react-flow-renderer";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CoursePath.css";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";
import { Modal, Button } from "react-bootstrap";

const CoursePath = ({
    nodes,
    edges,
    title,
    courses,
    coursesProgress,
    reFetchData,
}) => {
    const { fitView } = useReactFlow();
    const reactFlowInstance = useRef(null);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [currentNode, setCurrentNode] = useState({});
    const [currentCourse, setCurrentCourse] = useState({});

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onNodeClick = (event, node) => {
        const courseId = node.data.label; // Assuming course number is used as label
        const secCourse = courses.find((course) => course.id === courseId);
        const courseProg = coursesProgress.find(
            (course) => course.courseNumber === secCourse.courseNumber
        );
        setCurrentNode(node);
        setCurrentCourse(courseProg);
        handleShow();
    };

    const handleAction1 = async (prog = 1.0) => {
        const courseId = currentNode.data.label; // Assuming course number is used as label
        const course = courses.find((course) => course.id === courseId);
        const user_id = localStorage.getItem("user_id");
        await axiosInterceptor.patch(
            `course-progress/update/${user_id}/`,
            {
                updates: [
                    {
                        courseNumber:
                            // course.name ===
                            //     "Topics in Math with Applications in Finance" ||
                            // course.id.includes("C++")
                            //     ? course.courseNumber + "-2"
                            // :
                            course.courseNumber,
                        progress: prog,
                    },
                ],
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        reFetchData();
        handleClose();
        window.location.reload();
    };

    const handleAction2 = () => {
        const courseNumber = currentNode.data.label; // Assuming course number is used as label
        const course = courses.find((course) => course.id === courseNumber);
        if (course) {
            const url = `/courses/${courseNumber}/lectures/1`;
            navigate(url);
        }
        handleClose();
    };

    useEffect(() => {
        fitView({ padding: 0.2 });
    }, [fitView]);

    return (
        <div className="h-full">
            <div className="rounded-xl absolute border border-gray-600 border-opacity-0 bg-white p-2.5 z-10 bottom-4 lg:bottom-auto left-4 lg:left-auto lg:top-4 lg:right-4">
                <h4 className="font-semibold absolute -top-3 left-2 bg-white p-1">
                    Key
                </h4>
                <div className="flex items-center -mb-1 mt-2">
                    <div
                        className="rounded"
                        style={{
                            width: "28px",
                            height: "28px",
                            backgroundColor: "#d1e7ff",
                            marginRight: "10px",
                        }}
                    ></div>
                    <p className="font-medium mt-2 !text-sm">Mastered</p>
                </div>
                <div className="flex items-center -mb-1">
                    <div
                        className="rounded"
                        style={{
                            width: "28px",
                            height: "28px",
                            backgroundColor: "#CCD9AB",
                            marginRight: "10px",
                        }}
                    ></div>
                    <p className="font-medium mt-2 !text-sm">Recommended</p>
                </div>
                <div className="flex items-center -mb-1">
                    <div
                        className="rounded"
                        style={{
                            width: "28px",
                            height: "28px",
                            backgroundColor: "#fff3cd",
                            marginRight: "10px",
                        }}
                    ></div>
                    <p className="font-medium mt-2 !text-sm">In Progress</p>
                </div>
                <div className="flex items-center -mb-1">
                    <div
                        className="rounded"
                        style={{
                            width: "28px",
                            height: "28px",
                            backgroundColor: "#f8d7da",
                            marginRight: "10px",
                        }}
                    ></div>
                    <p className="font-medium mt-2 !text-sm">
                        Need More Prerequisites
                    </p>
                </div>
            </div>
            <h2>{title}</h2>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Course Actions</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    {currentCourse &&
                        (!currentCourse.progress ||
                            currentCourse.progress < 1.0) && (
                            <Button
                                variant="secondary"
                                onClick={() => handleAction1(1.0)}
                            >
                                Set as Mastered
                            </Button>
                        )}
                    {currentCourse && currentCourse.progress >= 1.0 && (
                        <Button
                            variant="secondary"
                            onClick={() => handleAction1(0.0)}
                        >
                            Unmaster Course
                        </Button>
                    )}
                    <Button variant="primary" onClick={handleAction2}>
                        Go to Course
                    </Button>
                </Modal.Footer>
            </Modal>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                ref={reactFlowInstance}
                onInit={(instance) => {
                    instance.fitView({ padding: 0.2 });
                    reactFlowInstance.current = instance;
                }}
                onLoad={() => fitView({ padding: 0.2 })}
                zoomOnScroll={true}
                panOnDrag={true}
                onNodeClick={onNodeClick}
                style={{ width: "100%", height: "100%" }}
            >
                <Background />
            </ReactFlow>
        </div>
    );
};

export default CoursePath;
