import React, { useState } from 'react';
import { Container, Row, Col, ListGroup, ProgressBar, Card } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './CourseOverview.css'; // Create and import this CSS file for custom styles
import { useNavigate } from 'react-router-dom';

const coursesData = [
    { 
        name: 'Introduction To CS in Python', 
        completed: true, 
        blurb: 'little blurb explaining the class', 
        prerequisites: 'none', 
        prerequisiteFor: 'Introduction To EECS, Mathematics for CS (Discrete), Programming in Java' 
    },
    { name: 'Introduction To EECS', completed: false, blurb: '', prerequisites: '', prerequisiteFor: '' },
    { name: 'Mathematics for CS (Discrete)', completed: false, blurb: '', prerequisites: '', prerequisiteFor: '' },
    { name: 'Programming in Java', completed: false, blurb: '', prerequisites: '', prerequisiteFor: '' },
];

const CourseOverviewMain = () => {
    const [selectedCourse, setSelectedCourse] = useState(coursesData[0]);
    const navigate = useNavigate();

    const handleClassSelect = () => {
        navigate(`/course-recommender/class-overview`)
    }

    return (
        <Container fluid className="mt-5">
            <Row className="justify-content-center">
                <Col xs={12} md={5} className="p-4">
                    <div className="box-gradient">
                        <h3>Computer Science</h3>
                        <hr style={{ border: '2px solid black', width: '100%' }} />
                        <div className="progress-container">
                            <ListGroup>
                                {coursesData.map((course, index) => (
                                    <ListGroup.Item 
                                        key={index} 
                                        className="d-flex align-items-center course-item"
                                        action
                                        onClick={() => setSelectedCourse(course)}
                                        onDoubleClick={() => handleClassSelect()}
                                        active={selectedCourse.name === course.name}
                                    >
                                        <div className={`circle ${course.completed ? 'completed' : ''}`}>
                                            {course.completed && <i className="bi bi-check"></i>}
                                        </div>
                                        <div className="course-name">{course.name}</div>
                                   </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={5} className="p-4">
                    <div className="box-gradient">
                        <Card className='course-blob'>
                            <Card.Body>
                                <Card.Title>{selectedCourse.name}</Card.Title>
                                <hr style={{ border: '2px solid black', width: '100%' }} />
                                <Card.Text>
                                    {selectedCourse.blurb}
                                </Card.Text>
                                <hr style={{ border: '2px solid black', width: '100%' }} />
                                <Card.Text>
                                    Prerequisites: {selectedCourse.prerequisites}
                                </Card.Text>
                                <hr style={{ border: '2px solid black', width: '100%' }} />
                                <Card.Text>
                                    This class is a prerequisite to: {selectedCourse.prerequisiteFor}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Row>
            <div style={{ position: 'relative', height: '200px' }}>
                <div className="d-flex align-items-center mt-4 position-bottom-left">
                                <div className="me-2">Computer Science</div>
                                <ProgressBar now={45} label="45%" style={{ width: '150px', marginLeft: '10px' }} />
                </div>
            </div>
        </Container>
    );
};

export default CourseOverviewMain;
