import React from "react";
import { useState } from "react";
// import Modal from "../Modal";
import EditProfile from "./EditProfile.jsx";
import Avatar from "boring-avatars";
import { Button, Modal } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";

export default function PersonalInfoCard({ user, user_data }) {
    const [showEdit, setShowEdit] = useState(false);
    const [showHoverCard, setShowHoverCard] = useState(false);

    const { birthday, address, visa, countries } = user;

    const handleCloseEdit = () => setShowEdit(false); // Function to close the modal
    const handleShowEdit = () => setShowEdit(true); // Function to show the modal

    return (
        <div className="flex flex-col w-full gap-y-3 p-6 rounded-lg shadow-card bg-white">
            <div className="flex flex-row justify-between items-center relative">
                <h4 className="text-black text-lg font-medium">
                    Personal Information
                </h4>
                <InfoCircle
                    className="inline-block ml-2 cursor-pointer"
                    onMouseEnter={() => setShowHoverCard(true)}
                    onMouseLeave={() => setShowHoverCard(false)}
                    onClick={() => setShowHoverCard(!showHoverCard)} // for mobile support
                />
                {showHoverCard && (
                    <div class="absolute top-8 z-10 text-sm w-full right-0 bg-white p-2 shadow border rounded-xl text-wrap  select-none font-normal">
                        Your personal information won’t be shown to your
                        employers or any applications submitted.
                    </div>
                )}
            </div>
            <div className="flex flex-col gap-y-0.5">
                <label className="text-xs font-medium text-gray-500">DOB</label>
                <p className="text-lg font-medium text-black">{birthday}</p>
            </div>
            <div className="flex flex-col gap-y-0.5">
                <label className="text-xs font-medium text-gray-500">
                    Address
                </label>
                <p className="text-lg font-medium text-black">{address}</p>
            </div>
            <div className="flex flex-col gap-y-0.5">
                <label className="text-xs font-medium text-gray-500">
                    Visa Status
                </label>
                <p className="text-lg font-medium text-black">
                    {visa && visa !== "false" && visa !== "no" ? "Yes" : "No"}
                </p>
            </div>
            <div className="flex flex-col gap-y-0.5">
                <label className="text-xs font-medium text-gray-500">
                    Countries of Work
                </label>
                <p className="text-lg font-medium text-black">{countries}</p>
            </div>
            <Button
                variant="light"
                className="float-right ml-auto mr-0"
                onClick={() => handleShowEdit()}
            >
                <i className="mr-2 bi bi-pencil-square"></i>
                Edit
            </Button>
            <Modal show={showEdit} onHide={handleCloseEdit} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EditProfile existingInfo={user_data}/>
            </Modal.Body>
            </Modal>
        </div>
    );
}
