import React from "react";
import { Link, useRouteError } from "react-router-dom";

export function Error() {
  const error = useRouteError();

  return (
    <div>
      <div class="d-flex align-items-center justify-content-center">
        <div class="text-center">
          <h1 class="display-1 fw-bold">Error</h1>
          <p class="fs-3">
            <span class="text-danger">Oops!</span> {error.message}
          </p>
          <Link to="/" class="btn btn-primary">
            Go Back Home
          </Link>
        </div>
      </div>
    </div>
  );
}
