import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import { Button, Container, Alert, Form } from "react-bootstrap";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";

function TechnicalHome() {
    const navigate = useNavigate();
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [selectedDifficulty, setSelectedDifficulty] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const handleSubjectChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSubjects(() => {
            if (selectedSubjects.includes(selectedValue)) {
                return selectedSubjects.filter(
                    (subject) => subject !== selectedValue
                );
            } else {
                return [...selectedSubjects, selectedValue];
            }
        });
    };

    const handleDifficultyChange = (event) => {
        setSelectedDifficulty(event.target.value);
    };

    const handleStartInterview = async () => {
        if (!selectedSubjects || !selectedDifficulty) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
            const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
            const request = { num_samples: 5, subjects: selectedSubjects };
            console.log(request);
            try {
                const response = await axiosInterceptor.post(
                    "/sample_questions/",
                    request,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRFToken": csrfToken,
                        },
                    }
                );
                // console.log(response)
                navigate("/test-center/technical", { state: response.data });
            } catch (error) {
                console.log(error);
                // For testing purposes — backend is still unfinished!
                navigate("/test-center/technical");
            }
        }
    };

    return (
        <Container fluid className="min-vh-100 py-4" style={{ backgroundColor: "#ffffff" }}>
        {/* <Container fluid className="bg-light min-vh-100 py-4"> */}
            <div className="mb-3 flex flex-col items-start gap-y-4">
                <h1 className="text-3xl font-medium text-left">
                    Technical Exam
                </h1>
            </div>
            <div className="bg-white shadow-lg rounded-3xl p-4">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                    <div className="lg:col-span-2">
                        <h5 className="mb-3 text-lg font-semibold">
                            Instructions
                        </h5>
                        <p className="mb-4 text-gray-800">
                            Choose a difficulty and select subjects before you
                            start the interview. This is to tailor your
                            questions to your interests and skill level.
                        </p>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label className="text-sm">
                                    Choose subjects:
                                </Form.Label>
                                <Form.Select
                                    multiple
                                    value={selectedSubjects}
                                    onChange={handleSubjectChange}
                                    className="text-sm h-40"
                                >
                                    <option value="" disabled>
                                        --Please choose a subject--
                                    </option>
                                    {/* NOTE: these options were here before, but not implemented on backend */}
                                    <option value="mathcompetition">
                                        Math Competition
                                    </option>
                                    <option value="logicreasoning">
                                        Logic/Reasoning
                                    </option>
                                    <option value="fundamentalfinance">
                                        Fundamental Finance
                                    </option>
                                    <option value="specializedfinance">
                                        Specialized Finance
                                    </option>
                                    <option value="codingsyntaxproficiency">
                                        Coding Syntax Proficiency
                                    </option>
                                    <option value="algorithmsdatastructures">
                                        Algorithms/Data Structures
                                    </option>
                                    <option value="backend">Backend</option>
                                    <option value="gametheory">
                                        Game Theory
                                    </option>
                                    <option value="marketmaking">
                                        Market Making
                                    </option>
                                    <option value="statistics">
                                        Statistics
                                    </option>
                                    <option value="probability">
                                        Probability
                                    </option>
                                    <option value="fundamentalmath">
                                        Fundamental Math
                                    </option>
                                    <option value="basicmachinelearning">
                                        Basic Machine Learning
                                    </option>
                                    <option value="complexmachinelearning">
                                        Complex Machine Learning
                                    </option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="text-sm">
                                    Choose a difficulty:
                                </Form.Label>
                                <Form.Select
                                    value={selectedDifficulty}
                                    onChange={handleDifficultyChange}
                                    className="text-sm"
                                >
                                    <option value="" disabled>
                                        --Please choose a difficulty--
                                    </option>
                                    <option value="easy">Easy</option>
                                    <option value="medium">Medium</option>
                                    <option value="hard">Hard</option>
                                </Form.Select>
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="lg:col-span-1 m-auto lg:mx-0">
                        <div className="webcam-container lg:float-right">
                            <Webcam className="rounded w-full max-w-[300px]" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-4 flex flex-col sm:flex-row justify-end gap-3">
                <Button
                    onClick={handleStartInterview}
                    variant="primary"
                    className="bg-special-blue border-0 rounded-full text-sm px-4"
                >
                    Start Interview
                </Button>
            </div>
            {showAlert && (
                <Alert variant="danger">
                    Please choose a course and difficulty before starting the
                    interview.
                </Alert>
            )}
        </Container>
    );
}

export default TechnicalHome;
