import axios from "axios";
import { API_BASE_URL } from "../constants";

const axiosInterceptor = axios.create({
  baseURL: API_BASE_URL,
  // other configurations
});

const refreshToken = async () => {
  try {
    const response = await axiosInterceptor.post("/refresh_tokens/", {
      refresh_token: localStorage.getItem("refresh_token"),
    });
    localStorage.setItem("access_token", response.data.access_token);
    localStorage.setItem("refresh_token", response.data.refresh_token);
    return response.data.access_token;
  } catch (error) {
    // NOTE: we will log out because the probability of in-15-min-window + wifi drop is very low.
    if (error.response.status >= 400 && error.response.status <= 500) {
      logout();
    }
    return null;
  }
};

const logout = () => {
  localStorage.clear();
  window.location.href = "/account/login";
};

axiosInterceptor.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// If the response status is 401 and the error code is token_not_valid,
// then we will call the refreshToken function to get a new access token.
// If the new access token is successfully obtained, we will update the
// Authorization header with the new access token and resend the request.
// If the new access token is not obtained, we will log out the user.
axiosInterceptor.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.error === "Invalid access token"
    ) {
      const newToken = await refreshToken();
      if (newToken) {
        error.config.headers.Authorization = `Bearer ${newToken}`;
        return axiosInterceptor(error.config);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInterceptor;
