import QandA from "./QandA";
// import { Model } from "survey-core";
// import { Survey } from "survey-react-ui";
// import axiosInterceptor from "../../../utils/axiosInterceptor.ts";

import "survey-core/defaultV2.min.css";
import React from "react";

// import React, { useState } from 'react';
// import { Document, Page } from 'react-pdf';
// import { pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url,
// ).toString();

function PdfIframe({ pdfUrl }) {
    return (
        <div
            className="overflow-auto mt-3 d-flex flex-column align-items-center bg-dark"
            style={{ maxWidth: "100%", height: "100vh", overflowY: "auto" }}
        >
            <iframe
                title="Unique Title for Iframe" // Make sure to replace this with a meaningful title that reflects the content or purpose of the iframe.
                // ...other props
                src={pdfUrl}
                type="application/pdf"
                style={{
                    width: "100%",
                    height: "100%",
                }}
                frameBorder="0"
            >
                This browser does not support PDFs. Please download the PDF to
                view it: <a href={pdfUrl}>Download PDF</a>.
            </iframe>
        </div>
    );
}

// function Pdf({ pdfUrl }) {
//   const [numPages, setNumPages] = useState(null);
//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };
//   return (
//     <>
//       <div
//         className="overflow-auto mt-3 d-flex flex-column align-items-center bg-dark"
//         style={{ maxWidth: '100%', height: '100vh', overflowY: 'auto' }}
//       >

//         <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} style={{ width: '100%' }}>
//           {Array.from(new Array(numPages), (x, index) => (
//             <Page
//               renderTextLayer={false}
//               renderAnnotationLayer={false}
//               customTextRenderer={false}
//               key={index + 1}
//               pageNumber={index + 1}
//             />
//           ))}
//          </Document>
//       </div>

//     </>
//   );
// }

function Video({ videoUrl }) {
    return (
        <video controls className="mx-auto h-full rounded-2xl w-full">
            <source src={videoUrl} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
        </video>
    );
}

function Resources({ content, mainDownloadURL }) {
    const resourcesTab = content.tabs.find((tab) => tab.name === "Resources");
    const resourcesDescription = resourcesTab ? resourcesTab.description : [];

    const titleCase = (string) => {
        return string
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };

    return (
        <div
            className="tab px-3 py-2 flex-fill overflow-auto"
            style={{
                maxWidth: "100%",
                maxHeight: "100%",
                overflowY: "auto",
                marginBottom: "100px",
            }}
        >
            <ul className="pl-4">
                <li className="list-disc">
                    <a href={mainDownloadURL} target="_blank" rel="noreferrer">
                        {titleCase(
                            mainDownloadURL
                                .split("/")
                                .pop()
                                .replace(/_/g, " ")
                                .split(".")[0]
                        )
                            .replace("Problems", "Problem")
                            .replace("Notes", "Note")
                            .replace("Videos", "Video")}
                    </a>
                </li>
                {resourcesDescription.map((url, index) => (
                    <li key={index}>
                        <a href={url} target="_blank" rel="noreferrer">
                            {`Support File ${index}`}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

// function Upload({content, params}) {
//   const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];

//   const user_id = localStorage.getItem('user_id');

//   // Split the path by '/'
//   const pathParts = content.mainFilesList[0].split('/');

//   // Remove the first three elements ('public', 'static', and 'course_materials') and the last element (file name)
//   pathParts.splice(0, 3); // Remove 'public', 'static', and 'course_materials'
//   pathParts.pop(); // Remove the file name

//   // Join the remaining parts back into a path
//   const directoryPath = pathParts.join('/');

//   const json = {
//     "elements": [
//       {
//         "type": "file",
//         "title": "Please upload your files",
//         "name": "files",
//         "storeDataAsText": false,
//         "waitForUpload": true,
//         "allowMultiple": true,
//         "maxSize": 104857600,
//         "hideNumber": true,
//         "backgroundColor": "#000000"
//       }
//     ]
//   };
//   const survey = new Model(json);
//   survey.showCompletedPage = false;
//   survey.onComplete.add((sender, options) => {
//       console.log(JSON.stringify(sender.data, null, 3));
//   });

//   survey.onUploadFiles.add((_, options) => {
//     const formData = new FormData();
//     options.files.forEach((file) => {
//         formData.append('files', file); // 'files' is the key
//     });

//     formData.append('directoryPath', directoryPath);

//     // Replace this URL with your backend endpoint
//     // Perform the file upload using axios
//     axiosInterceptor.post(`file_upload/${user_id}/`, formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data', // This header is set for file upload
//         'X-CSRFToken': csrfToken,
//       }
//     })
//     .then(async (uploadResponse) => {
//       console.log('Upload successful', uploadResponse.data);

//       // Assuming the upload was successful, now trigger processing
//       const processResponse = await axiosInterceptor.post(`process-and-rate-submissions/`, {
//         directoryPath: directoryPath, // or any other relevant information
//       }, {
//         headers: {
//           'Content-Type': 'application/json',
//           'X-CSRFToken': csrfToken,
//         }
//       });

//       console.log('Processing successful', processResponse.data);
//     })
//     .catch((error) => {
//       console.error("Error during file upload or processing: ", error);
//     });
// });

//   async function deleteFile(fileURL, options) {
//       try {
//           const name = fileURL.split("=")[1];
//           const apiUrl = `https://api.surveyjs.io/private/Surveys/deleteTempFile?name=${name}`;
//           const response = await fetch(apiUrl, {
//               method: "DELETE"
//           });

//           if (response.status === 200) {
//               console.log(`File ${name} was deleted successfully`);
//               options.callback("success");
//           } else {
//               console.error(`Failed to delete file: ${name}`);
//               options.callback("error");
//           }
//       } catch (error) {
//           console.error("Error while deleting file: ", error);
//           options.callback("error");
//       }
//   }

//   survey.onClearFiles.add(async (_, options) => {
//       if (!options.value || options.value.length === 0)
//           return options.callback("success");
//       if (!options.fileName && !!options.value) {
//           for (const item of options.value) {
//               await deleteFile(item.content, options);
//           }
//       } else {
//           const fileToRemove = options.value.find(
//               (item) => item.name === options.fileName
//           );
//           if (fileToRemove) {
//               await deleteFile(fileToRemove.content, options);
//           } else {
//               console.error(`File with name ${options.fileName} is not found`);
//           }
//       }
//   });
//   return (<div>
//     <Survey model={survey} />
//     </div>);
// }

function Discussion({ content }) {
    console.log("in discussion");
    console.log(content);
    const questions = [
        {
            question: "What is biology?",
            replies: [
                "The study of living organisms, divided into many specialized fields that cover their morphology, physiology, anatomy, behavior, origin, and distribution.",
            ],
        },
    ];
    return (
        <div className="px-3 pb-3 place-self-start justify-self-start self-start mt-0 mb-auto">
            <div className="input-group input-group">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Write your question here"
                    ariaLabel="Question"
                    ariaDescribedby="addon-wrapping"
                />
                <button className="btn btn-outline-secondary" type="button">
                    Post
                </button>
            </div>
            <QandA content={questions} />
        </div>
    );
}

export {
    // Pdf,
    PdfIframe,
    Video,
    // Upload,
    Resources,
    Discussion,
};
