import React from "react";
import ProfileCard from "../../components/profile/ProfileCard.jsx";
import PersonalInfoCard from "../../components/profile/PersonalInfoCard.jsx";
import "../../assets/userAssets/css/main.css";
import CareerProfile from "./CareerProfile.jsx";
import { useState } from "react";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";
import ScreenSpinner from "../../components/ScreenSpinner.jsx";

export default function Profile() {
    const [userdata, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const user_id = localStorage.getItem("user_id");
    React.useEffect(() => {
        try {
            axiosInterceptor.get(`/getuser/${user_id}/`).then((response) => {
                setUserData(response.data["u_data"]);
                setIsLoading(false);
            });
        } catch (error) {
            console.error("error:", error);
            setIsLoading(false);
        }
    }, [user_id]);

    const user = {
        name: userdata.firstname + " " + userdata.lastname,
        email: userdata.email,
        phone: userdata.phone_number,
        website: userdata.website,
        linkedin: userdata.linkedin,
        birthday: userdata.dob,
        address: userdata.street_addr + " " + userdata.city_addr,
        visa: userdata.has_visa,
        countries: userdata.job_country,
    };

    const eduction = "Fill in"

    return isLoading ? (
        <ScreenSpinner />
    ) : (
        <div className="flex flex-row py-4 gap-x-5">
            <div className="w-96 flex flex-col gap-y-3">
                <ProfileCard user={user} user_data={userdata}/>
                <PersonalInfoCard user={user} user_data={userdata}/>
            </div>
            <div className="w-full">
                <CareerProfile />
            </div>
        </div>
    );
}
