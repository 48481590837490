import React from "react";
import Logo from "../assets/img/logo.png";

function Footer() {
    return (
        <div
            className="px-6 py-4 mt-20"
            style={{
                backgroundColor: "#6589b2",
            }}
        >
            <div>
                <img
                    src={Logo}
                    alt="AlgoLink Logo"
                    className="d-inline-block align-top me-1 h-10"
                />
                {/* <span className="text-white ml-0.5">
                    AlgoLink Enterprise
                </span> */}
            </div>
        </div>
    );
}
export default Footer;
