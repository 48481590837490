import React from "react";
// import { useNavigate } from "react-router-dom";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { Typeahead } from "react-bootstrap-typeahead";
// import DatePicker from 'react-date-picker';

function EditProfile({ existingInfo }) {

  const BLANK_PERSONAL = {
    firstname: "",
    lastname: "",
    email: "",
    phone_number: "",
    street_addr: "",
    city_addr: "",
    state_addr: "",
    zip_code: "",
    country_addr: "",
    password: "",
    password_verify: "",
    dob: "",
    has_visa: "Yes",
    job_country: []
  }

  var start_info = BLANK_PERSONAL

  if (existingInfo != null && JSON.stringify(existingInfo) !== "{}"){start_info = existingInfo}

  const [user, setPersonal] = React.useState(start_info);
  const [countries, setCountries] = React.useState(start_info.job_country);

  function handleChange(e) {
    setPersonal({ ...user, [e.target.name]: e.target.value });
    console.log(user)
    console.log('countries: ', countries)
  }

  const handleCountryChange = (selected) =>{
    setCountries(selected);
    setPersonal({ ...user, job_country: selected });
    console.log('Selected countries: ', selected);
  };

  const handleSubmit = async (event) => {
    event.preventDefault()

    const user_id = localStorage.getItem('user_id')
    console.log(user)
    
    try {
      const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];

      await axiosInterceptor.patch(`/updateuser/`+user_id+'/', user, {
          headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken,
              }
          });

      // Reload
      window.location.reload();
    } catch(error) { 
        if (error.response.data.email) {
          alert(
              "A user with this email already exists. Please use a different email."
          );
        }
      }
  }

  const visa_options = [
    'Yes',
    'No',
  ];

  const country_options = [
    "United States", "Canada", "Afghanistan", "Albania", "Algeria", "American Samoa", 
    "Andorra", "Angola", "Anguilla", "Antarctica", "Antigua and/or Barbuda", "Argentina", "Armenia", 
    "Aruba", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", 
    "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia and Herzegovina", 
    "Botswana", "Bouvet Island", "Brazil", "British Indian Ocean Territory", "Brunei Darussalam", 
    "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon", "Cape Verde", "Cayman Islands", 
    "Central African Republic", "Chad", "Chile", "China", "Christmas Island", "Cocos (Keeling) Islands", 
    "Colombia", "Comoros", "Congo", "Cook Islands", "Costa Rica", "Croatia (Hrvatska)", "Cuba", "Cyprus", 
    "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecudaor", "Egypt", 
    "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Ethiopia", "Falkland Islands (Malvinas)", "Faroe Islands", 
    "Fiji", "Finland", "France", "France, Metropolitan", "French Guiana", "French Polynesia", "French Southern Territories",
    "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Gibraltar", "Greece", "Greenland", "Grenada", "Guadeloupe", "Guam", 
    "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Heard and Mc Donald Islands", "Honduras", "Hong Kong", "Hungary",
    "Iceland", "India", "Indonesia", "Iran (Islamic Republic of)", "Iraq", "Ireland", "Israel", "Italy", "Ivory Coast", 
    "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea, Democratic People's Republic of", 
    "Korea, Republic of", "Kosovo", "Kuwait", "Kyrgyzstan", "Lao People's Democratic Republic", "Latvia", "Lebanon",
    "Lesotho", "Liberia", "Libyan Arab Jamahiriya", "Liechtenstein", "Lithuania", "Luxembourg", "Macau", "Macedonia",
    "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Martinique", "Mauritania",
    "Mauritius", "Mayotte", "Mexico", "Micronesia, Federated States of", "Moldova, Republic of", "Monaco", "Mongolia",
    "Montserrat", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "Netherlands Antilles", 
    "New Caledonia", "New Zealand", "Nicaragua", "Niger", "Nigeria", "Niue", "Norfork Island", "Northern Mariana Islands",
    "Norway", "Oman", "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Pitcairn", 
    "Poland", "Portugal", "Puerto Rico", "Qatar", "Reunion", "Romania", "Russian Federation", "Rwanda", 
    "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", 
    "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia",
    "Solomon Islands", "Somalia", "South Africa", "South Georgia South Sandwich Islands", "South Sudan", "Spain", 
    "Sri Lanka", "St. Helena", "St. Pierre and Miquelon", "Sudan", "Suriname", "Svalbarn and Jan Mayen Islands", "Swaziland",
    "Sweden", "Switzerland", "Syrian Arab Republic", "Taiwan", "Tajikistan", "Tanzania, United Republic of", "Thailand",
    "Togo", "Tokelau", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Turks and Caicos Islands",
    "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States minor outlying islands",
    "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City State", "Venezuela", "Vietnam", "Virigan Islands (British)", 
    "Virgin Islands (U.S.)", "Wallis and Futuna Islands", "Western Sahara", "Yemen", "Yugoslavia", "Zaire", "Zambia", "Zimbabwe",
  ]


  return (
    <Container style={{ maxWidth: 500, padding: 10 }}>
      <Form onSubmit={handleSubmit}>
        <Stack gap={2}>
          <h3>Personal Information</h3>
          <FloatingLabel label="First Name">
            <Form.Control
              name="firstname"
              type="text"
              pattern="[A-z ]+"
              placeholder=" "
              required
              value={user.firstname}
              onChange={handleChange}
            />
          </FloatingLabel>

          <FloatingLabel label="Last Name">
            <Form.Control
              name="lastname"
              type="text"
              pattern="[A-z \-]+"
              placeholder=" "
              required
              value={user.lastname}
              onChange={handleChange}
            />
          </FloatingLabel>
          <FloatingLabel label="Email">
            <Form.Control
              name="email"
              type="email"
              placeholder=" "
              required
              value={user.email}
              onChange={handleChange}
            />
          </FloatingLabel>
          <FloatingLabel label="Phone Number">
            <Form.Control
              name="phone_number"
              type="phone_number"
              placeholder=" "
              required
              value={user.phone_number}
              onChange={handleChange}
            />
          </FloatingLabel>

          <Form.Label className="m-0">Birthday</Form.Label>
          <Form.Control
            type="date"
            name="dob"
            placeholder="Birthday"
            onChange={handleChange}
            value={user.dob}
          />
          
          <h3>Address Information</h3>
          <FloatingLabel label="Street">
            <Form.Control
              name="street_addr"
              type="street"
              placeholder=" "
              required
              value={user.street_addr}
              onChange={handleChange}
            />
          </FloatingLabel>
          <FloatingLabel label="City">
            <Form.Control
              name="city_addr"
              type="city"
              placeholder=" "
              required
              value={user.city_addr}
              onChange={handleChange}
            />
          </FloatingLabel>
          <FloatingLabel label="State">
            <Form.Control
              name="state_addr"
              type="state"
              placeholder=" "
              required
              value={user.state_addr}
              onChange={handleChange}
            />
          </FloatingLabel>
          <FloatingLabel label="Zip Code">
            <Form.Control
              name="zip_code"
              type="zip_code"
              placeholder=" "
              required
              value={user.zip_code}
              onChange={handleChange}
            />
          </FloatingLabel>
          <FloatingLabel label="Country">
            <Form.Control
              name="country_addr"
              type="country"
              placeholder=" "
              required
              value={user.country_addr}
              onChange={handleChange}
            />
            </FloatingLabel>

            <h2>Work Information</h2>
            {/* drop down for both visa and job country */}
            <Form.Label className="m-0">Have a US Work Visa:</Form.Label>
              <Form.Group controlId="formDropdown">
                <Form.Control 
                as="select"
                name="has_visa"
                value={user.has_visa}
                onChange={handleChange}
                >
                    {visa_options.map((visa, index) => 
                    <option value = {visa}> {visa} </option>)}
                </Form.Control>
              </Form.Group>
            
            <Form.Group className="m-0">
            <Form.Label>Countries Authorized to Work</Form.Label>
            <Typeahead
              id="skill-select"
              labelKey="job_country"
              multiple
              onChange={handleCountryChange}
              options={country_options}
              placeholder="Select your Countries"
              selected={countries}
              allowNew
              newSelectionPrefix=""
            />
          </Form.Group>


            <h2>Profesional Info</h2>
            <FloatingLabel label="LinkedIn">
            <Form.Control
              name="linkedin"
              type="webpage"
              placeholder=" "
              required
              value={user.linkedin}
              onChange={handleChange}
            />
          </FloatingLabel>

          <FloatingLabel label="GitHub">
            <Form.Control
              name="github"
              type="webpage"
              placeholder=" "
              required
              value={user.github}
              onChange={handleChange}
            />
          </FloatingLabel>

          <FloatingLabel label="Website">
            <Form.Control
              name="website"
              type="webpage"
              placeholder=" "
              required
              value={user.website}
              onChange={handleChange}
            />
          </FloatingLabel>
          <Container style={{ textAlign: "center" }}>
            <Button type="submit" onClick={handleSubmit}>
              Done
            </Button>
          </Container>
        </Stack>
      </Form>
    </Container>
  );
}

export default EditProfile;
