import React from "react";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { Upload } from "../../Upload";

export default function Resume({ resume }) {
  return (
    <a className="resume-link one-line" href="https://google.com">
      {resume.title}
    </a>
  );
}

export function CreateRes({
  resume,
  // onUploadReady,
  // onUploadSuccess,
  // isUploadReady,
  userId,
}) {
  // const handleFileChange = (e) => {
  //   console.log(resume)
  //   setResume(e.target.files[0])
  // }
  function handleSubmit(e) {
    //Prevent Reload
    e.preventDefault();

    // Read the form data
    const formData = new FormData();
    formData.append("resume", resume);
  }

  return (
    <>
      <Form>
        <Stack gap={2}>
          <Upload
            content={resume}
            params={{}}
            rootDirectory="resumes"
            directoryPath=""
            uploadEndpoint={`file_upload/`}
            processEndpoint={`process_resumes/`}
            // onUploadSuccess={onUploadSuccess} // Pass the prop to Upload
            userId={userId}
          />
        </Stack>
      </Form>
    </>
  );
}
