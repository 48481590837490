import React from "react";

const ScreenSpinner = ({ extraClasses = "" }) => {
    return (
        <div
            className={"flex justify-center w-full " + extraClasses}
            style={{
                height: `calc(100vh - ${document.getElementById("switchBetween")
                    ?.clientHeight ?? 0}px - 22px - 32px)`,
            }}
        >
            <div className="mt-auto mb-auto lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default ScreenSpinner;
