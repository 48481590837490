import React, { useState, useEffect } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { python } from "@codemirror/lang-python";
import { cpp } from "@codemirror/lang-cpp";
import { tokyoNight } from "@uiw/codemirror-theme-tokyo-night";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";
import { Button } from "react-bootstrap";

const languageOptions = {
    javascript: javascript(),
    python: python(),
    cpp: cpp(),
};

const languageStartingCode = {
    javascript: "console.log('hello, world');",
    python: "print('hello, world')",
    cpp:
        "#include <iostream>\n\nusing namespace std;\n\nint main() {\n cout << 'hello, world';\n return 0;\n}",
};

const CodingBox = ({
    setParentTestCases,
    parentCurrentTestCase,
    setParentCurrentTestCase,
}) => {
    const [code, setCode] = useState(languageStartingCode.python);
    const [language, setLanguage] = useState("python");
    const [localTestCases, setLocalTestCases] = useState([]);
    const [testResults, setTestResults] = useState([]);
    const [consoleState, setConsoleState] = useState(true);

    useEffect(() => {
        const fetchTestCases = async () => {
            const data = await testCaseData();
            const newTestCases = data.map(({ input, expected_output }) => ({
                input,
                expected_output,
                component: createTestCase(input),
            }));
            setLocalTestCases(newTestCases);
            setParentTestCases(newTestCases);
            setParentCurrentTestCase(0);
        };
        fetchTestCases();
    }, [setParentTestCases, setParentCurrentTestCase]);

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);
        setCode(languageStartingCode[e.target.value]);
    };

    const createTestCase = (input) => (
        <div className="mt-4">
            <p className="text-sm text-gray-600 mb-1">nums =</p>
            <div className="bg-[#D9D9D9] p-2 rounded">
                {JSON.stringify(input)}
            </div>
            <p className="text-sm text-gray-600 mb-1 mt-3">target =</p>
            <div className="bg-[#D9D9D9] p-2 rounded">
                {JSON.stringify(input)}
            </div>
        </div>
    );

    const createTestResult = (result, input, output, expected_output) => (
        <div className="mt-4">
            <p
                className={`text-lg font-bold ${
                    result === "Pass" ? "text-green-600" : "text-red-600"
                }`}
            >
                {result === "Pass" ? "Passed" : "Failed"}
            </p>
            <p className="text-sm text-gray-600 mb-1 mt-2">Input:</p>
            <div className="bg-[#D9D9D9] p-2 rounded">
                {JSON.stringify(input)}
            </div>
            <p className="text-sm text-gray-600 mb-1 mt-2">Output:</p>
            <div className="bg-[#D9D9D9] p-2 rounded">
                {JSON.stringify(output)}
            </div>
            <p className="text-sm text-gray-600 mb-1 mt-2">Expected Output:</p>
            <div className="bg-[#D9D9D9] p-2 rounded">
                {JSON.stringify(expected_output)}
            </div>
        </div>
    );

    const createTestCaseButtons = () =>
        localTestCases.map((_, index) => (
            <button
                key={index}
                onClick={() => setParentCurrentTestCase(index)}
                className={`px-3 py-1 rounded mr-2 ${
                    index === parentCurrentTestCase
                        ? "bg-gray-300"
                        : "bg-gray-200"
                }`}
            >
                Case {index + 1}
            </button>
        ));

    const testCaseData = async () => {
        try {
            const { data } = await axiosInterceptor.post("/run_code/", {
                code,
                language,
            });
            return data.results;
        } catch (error) {
            console.error("Error running code:", error);
        }
    };

    const handleSubmit = async () => {
        const data = await testCaseData();
        const newTestCases = data.map(({ input }) => createTestCase(input));
        const newTestResults = data.map(
            ({ result, input, output, expected_output }) =>
                createTestResult(result, input, output, expected_output)
        );
        setLocalTestCases(newTestCases);
        setParentTestCases(newTestCases);
        setTestResults(newTestResults);
        setParentCurrentTestCase(0);
        setConsoleState(false);
    };

    return (
        <div className="border border-gray-300 rounded-lg overflow-hidden">
            <div className="bg-[#1a1b26] text-white p-3 flex justify-between items-center">
                <div className="flex items-center">
                    <span className="mr-2">Language:</span>
                    <select
                        value={language}
                        onChange={handleLanguageChange}
                        className="bg-transparent text-white border-none"
                    >
                        {Object.keys(languageOptions).map((lang) => (
                            <option
                                key={lang}
                                value={lang}
                                className="text-black"
                            >
                                {lang.charAt(0).toUpperCase() + lang.slice(1)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="border-b border-gray-300">
                <CodeMirror
                    value={code}
                    height="260px"
                    theme={tokyoNight}
                    extensions={[languageOptions[language]]}
                    onChange={(value) => setCode(value)}
                />
            </div>
            <div>
                <div className="flex mb-3 bg-gray-200 p-2">
                    <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => setConsoleState(true)}
                        className={`${
                            consoleState ? "bg-gray-300" : "bg-gray-200"
                        }`}
                    >
                        Testcase
                    </Button>
                    {/* vertical line */}
                    {/* does not look very good, commented out for now */}
                    {/* <div className="w-0.5 h-8 bg-[#555555] mx-2">&nbsp;</div> */}
                    <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => setConsoleState(false)}
                        className={`${
                            !consoleState ? "bg-gray-300" : "bg-gray-200"
                        }`}
                    >
                        Result
                    </Button>
                </div>
                <div className="py-2 px-4">
                    <div className="mb-2 -mt-2">{createTestCaseButtons()}</div>
                    {consoleState
                        ? localTestCases[parentCurrentTestCase]?.component
                        : testResults[parentCurrentTestCase]}
                    <div className="flex justify-end mt-3 mb-2">
                        <Button
                            onClick={handleSubmit}
                            size="sm"
                            className="bg-[#1867FF] text-white px-6 rounded border-[#1867FF] active:bg-blue-600 active:border-blue-600 focus:bg-blue-600 focus:border-blue-600"
                        >
                            Run
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CodingBox;
