import React from 'react';
import Hero from '../../components/Hero';

function Success(props) {

  return (
    <div>
      <Hero content={"Success! We'll let you know when we find a job to refer you to"} />
  </div>
  );
}

export default Success
