import React, { Fragment } from "react";
import { useLoaderData } from "react-router-dom";
import Lecture from "../components/lectures/lectureInfo/Lecture";
import SEOWrapper from "../components/SEOWrapper";
import { API_BASE_URL } from "../constants";

import {
    getFilesFromDirectory,
    findDirectoryForLesson,
} from "../components/fileUtils";

export default function ContentTemplate() {
    const content = useLoaderData();

    let title, description;
    if (content.type === "pdf") {
        title = `${content.tabs[2].description.replaceAll(
            "-",
            " "
        )} - PDF Lecture - AlgoLink`;
        description = `Access PDF lecture materials for your AlgoLink course. Enhance your learning with supplementary resources and discussion.`;
    } else if (content.type === "video") {
        title = `${content.tabs[2].description.replaceAll(
            "-",
            " "
        )} - Video Lecture - AlgoLink`;
        description = `Watch video lectures for your AlgoLink course. Access supplementary resources and engage in course discussions.`;
    } else {
        title = `${content.tabs[2].description.replaceAll(
            "-",
            " "
        )} - AlgoLink`;
        description = `Access course materials and resources for your AlgoLink course.`;
    }

    return (
        <SEOWrapper title={title} description={description}>
            <Fragment>
                <Lecture content={content}> </Lecture>
            </Fragment>
        </SEOWrapper>
    );
}

export const contentLoader = async ({ params }) => {
    const courseId = params.courseId;
    const lessonId = parseInt(params.lessonId) - 1;
    const fileUrl = `https://ai-academy-storage-f951b86a124629-staging.s3.amazonaws.com/public/static/generated/${encodeURIComponent(
        courseId
    )}.json`;
    console.log(fileUrl);
    try {
        // const data = await fetch(apiUrl)
        const data = await fetch(fileUrl).then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        });
        console.log(data);
        const result = await findDirectoryForLesson(lessonId, data);
        console.log(result);
        const foundDirectory = result.foundDirectory;
        console.log(foundDirectory);

        const count = result.count;
        const mainDirectoryPath = `course_materials/${courseId}/file_store_${count}/${foundDirectory}`;
        console.log("Main directory Path");
        console.log(mainDirectoryPath);

        const mainFilesList = await getFilesFromDirectory(mainDirectoryPath);
        console.log("Main files list");
        console.log(mainFilesList);

        const supportDirectoryPath = `course_materials/${courseId}/support_file_store_${count}/${foundDirectory}`;
        const supportFilesList = await getFilesFromDirectory(
            supportDirectoryPath
        ); // can comment then uncomment for one successful run
        const supportFilesUrls = supportFilesList.map(
            (supportFile) =>
                `${API_BASE_URL}/serve_file/${supportFile.replace(
                    /^\/static\//,
                    ""
                )}`
        );
        const isPdf = mainFilesList[0].endsWith(".pdf");
        const isMp4 = mainFilesList[0].endsWith(".mp4");
        if (isPdf) {
            const content = {
                type: "pdf",
                mainFilesList: mainFilesList,
                tabs: [
                    { name: "Content", description: null },
                    { name: "Resources", description: supportFilesUrls },
                    { name: "Upload", description: courseId },
                    { name: "Discussion", description: courseId },
                ],
            };
            return content;
        } else if (isMp4) {
            const content = {
                type: "video",
                mainFilesList: mainFilesList,
                tabs: [
                    { name: "Content", description: null },
                    { name: "Resources", description: supportFilesUrls },
                    { name: "Upload", description: courseId },
                    { name: "Discussion", description: courseId },
                ], //{name: "Transcript", description: courseId},
            };
            return content;
        } else {
            return <div>Unsupported file type</div>;
        }
    } catch (error) {
        console.error("Error fetching or processing JSON:", error);
    }
};
