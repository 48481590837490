const skills = [
    "3D Modeling",
    "3D Printing",
    "3D Rendering",
    "Agile",
    "Agile Methodologies",
    "Agile Project Management",
    "AI",
    "Amazon DynamoDB",
    "Amazon RDS",
    "Android Development",
    "Angular",
    "AngularJS",
    "Ansible",
    "Apache Kafka",
    "Appium",
    "Artificial Intelligence",
    "Asana",
    "ActiveRecord",
    "Backbone.js",
    "Babel",
    "Big Data Analytics",
    "Bitbucket",
    "Bitbucket Pipelines",
    "Blockchain",
    "Bugzilla",
    "Bower",
    "C",
    "C#",
    "C++",
    "CORS",
    "CVS",
    "Cassandra",
    "Chai",
    "Charles",
    "Chef",
    "Ci/CD",
    "CoffeeScript",
    "CodeIgniter",
    "Complex Analysis",
    "Computer Graphics",
    "Computer Vision",
    "Concurrency",
    "Confluence",
    "Containerization",
    "CouchDB",
    "Couchbase",
    "D3.js",
    "DB2",
    "DIP",
    "DSP",
    "Dart",
    "Data Analysis",
    "Data Engineering",
    "Data Mining",
    "Data Science",
    "Data Scrubbing",
    "Data Structures",
    "Database Management",
    "Deep Learning",
    "Design Patterns",
    "DevOps",
    "Distributed Systems",
    "Django",
    "Docker",
    "Express.js",
    "Embedded Systems",
    "Event-Driven Architecture",
    "Excel",
    "Flask",
    "Frontend Development",
    "Full Stack Development",
    "Functional Programming",
    "Gatsby",
    "Game Development",
    "Git",
    "GitLab",
    "GitHub",
    "GitKraken",
    "Golang",
    "Google Cloud Platform",
    "GraphQL",
    "Grafana",
    "HTML",
    "Hadoop",
    "Hibernate",
    "High Level Mathematics",
    "ICT",
    "IIS",
    "Illustration",
    "Illustrator",
    "Information Architecture",
    "Internet of Things",
    "IoT",
    "JIRA",
    "Jenkins",
    "Java",
    "Java Programming",
    "JavaScript",
    "Jest",
    "Jira",
    "JSON",
    "Jupyter Notebooks",
    "Kafka",
    "Kanban",
    "Keras",
    "Kotlin",
    "Knockout.js",
    "Linux System Administration",
    "Machine Learning",
    "MariaDB",
    "Matlab",
    "Microservices",
    "Microsoft Azure",
    "Mocha",
    "Mongoose",
    "MongoDB",
    "MySQL",
    "NLP",
    "Nagios",
    "NestJS",
    "Next.js",
    "Node.js",
    "Numpy",
    "Objective-C",
    "Object-Oriented Programming",
    "OAuth",
    "OpenID Connect",
    "Operating Systems",
    "OpenGL",
    "Oracle",
    "PHP",
    "Pandas",
    "Parallel Computing",
    "Performance Tuning",
    "Perl",
    "PostCSS",
    "PostgreSQL",
    "Postman",
    "Power BI",
    "PowerShell",
    "Puppet",
    "PyTorch",
    "Python",
    "Qt",
    "Quarkus",
    "React.js",
    "React Native",
    "Redis",
    "Redux",
    "REST",
    "RESTful API Design",
    "RESTful Design Principles",
    "R",
    "RabbitMQ",
    "Ruby",
    "Ruby on Rails",
    "Rust",
    "SAML",
    "Sass",
    "Scala",
    "Scikit-Learn",
    "Selenium",
    "Security Engineering",
    "Sequelize",
    "Serverless Architecture",
    "Shell Scripting",
    "Splunk",
    "Spring Boot",
    "Spring Framework",
    "SQL",
    "Swift",
    "Svelte",
    "Selenium",
    "Service-Oriented Architecture (SOA)",
    "Selenium",
    "Symfony",
    "Svelte",
    "Swagger",
    "System Design",
    "TDD",
    "TCP/IP",
    "Terraform",
    "Test Driven Development (TDD)",
    "TestLink",
    "TestNG",
    "TestRail",
    "TensorFlow",
    "TypeORM",
    "TypeScript",
    "UI/UX Design",
    "Unity",
    "Vagrant",
    "Version Control",
    "Vue.js",
    "Web Assembly",
    "Web Development",
    "Web Security",
    "Webpack",
    "Windows Server Administration",
    "WordPress",
    "XML",
    "Xamarin",
    "Yii",
    "ZeroMQ",
    "gRPC",
    "golang",
    "iOS Development",
    "jQuery",
];

export default skills;
