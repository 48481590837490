import React, { useState, useEffect } from "react";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";

const JobPreferencesSurvey = () => {
    const [survey, setSurvey] = useState(null);

    useEffect(() => {
        const initializeSurvey = async () => {
            const prevData = await getPrevData();
            const surveyJson = createSurveyJson(prevData);
            const newSurvey = new Model(surveyJson);
            newSurvey.onComplete.add(async function(sender, options) {
                await handleSubmit(sender.data, options);
            });
            newSurvey.completedHtml =
                "Your job preferences were submitted successfully";
            setSurvey(newSurvey);
        };

        initializeSurvey();
    }, []);

    const getPrevData = async () => {
        try {
            const user_id = localStorage.getItem("user_id");
            if (user_id) {
                const response = await axiosInterceptor.get(
                    `/save_or_update_user_surveys/${user_id}/`
                );
                const data = response.data;
                if (data && data.job_pref_survey) {
                    return JSON.parse(data.job_pref_survey);
                } else {
                    throw Error("No job preference data found.");
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        return null;
    };

    const createSurveyJson = (prevData) => ({
        title: "Job Preferences",
        description:
            "Help us understand your job preferences so we can connect you with opportunities that are best suited for your individual goals",
        logoPosition: "right",
        pages: [
            {
                name: "page1",
                elements: [
                    {
                        type: "ranking",
                        name: "question1",
                        title:
                            "Rank the following industries in order of preference",
                        choices: [
                            {
                                value: "Item 1",
                                text: "Proprietary Trading Firms",
                            },
                            { value: "Item 2", text: "Hedge Funds" },
                            { value: "Item 3", text: "Big Tech" },
                            { value: "Item 4", text: "Tech Startups" },
                            { value: "Item 5", text: "BioTech & Healthcare" },
                            {
                                value: "Item 6",
                                text: "Finance & Investment Banks",
                            },
                            { value: "Item 7", text: "Aerospace & Defense" },
                            { value: "Item 8", text: "Entertainment & Gaming" },
                            { value: "Item 9", text: "Automotive" },
                            { value: "Item 10", text: "Energy" },
                            { value: "Item 11", text: "Telecommunications" },
                            { value: "Item 12", text: "Consulting" },
                            { value: "Item 13", text: "E-Commerce" },
                            { value: "Item 14", text: "Manufacturing" },
                            { value: "Item 15", text: "Education" },
                            {
                                value: "Item 16",
                                text: "Government & Public Sector",
                            },
                        ],
                        selectToRankEnabled: true,
                        defaultValue: prevData ? prevData.question1 : null,
                    },
                    {
                        type: "ranking",
                        name: "question2",
                        title:
                            "Rank the following job titles in order of preference",
                        choices: [
                            {
                                value: "Item 1",
                                text: "Quantitative Researcher",
                            },
                            { value: "Item 2", text: "Quantitative Trader" },
                            { value: "Item 3", text: "Quantitative Developer" },
                            { value: "Item 4", text: "Research Scientist" },
                            {
                                value: "Item 5",
                                text: "Machine Learning Engineer",
                            },
                            { value: "Item 6", text: "Data Scientist" },
                            { value: "Item 7", text: "Backend Engineer" },
                            { value: "Item 8", text: "Frontend Engineer" },
                            { value: "Item 9", text: "Product Manager" },
                            { value: "Item 10", text: "Data Analyst" },
                            { value: "Item 11", text: "UX/UI Designer" },
                        ],
                        selectToRankEnabled: true,
                        defaultValue: prevData ? prevData.question2 : null,
                    },
                    {
                        type: "ranking",
                        name: "question3",
                        title:
                            "Rank the following work locations in order of preference",
                        choices: [
                            { value: "Item 1", text: "Remote" },
                            { value: "Item 2", text: "Silicon Valley, CA" },
                            { value: "Item 3", text: "San Diego, CA" },
                            { value: "Item 4", text: "Los Angeles, CA" },
                            { value: "Item 5", text: "New York City, NY" },
                            { value: "Item 6", text: "Boston, MA" },
                            { value: "Item 7", text: "Seattle, WA" },
                            { value: "Item 8", text: "Austin, TX" },
                            { value: "Item 9", text: "Washington D.C." },
                            { value: "Item 10", text: "Atlanta, GA" },
                            { value: "Item 11", text: "Raleigh-Durham, NC" },
                            { value: "Item 12", text: "Chicago, IL" },
                            { value: "Item 13", text: "Pittsburgh, PA" },
                            {
                                value: "Item 14",
                                text: "Minneapolis-St. Paul, MN",
                            },
                            { value: "Item 15", text: "Salt Lake City, UT" },
                        ],
                        selectToRankEnabled: true,
                        defaultValue: prevData ? prevData.question3 : null,
                    },
                    {
                        type: "ranking",
                        name: "question4",
                        title:
                            "Rank the following company sizes in order of preference",
                        choices: [
                            { value: "Item 1", text: "2-10 employees" },
                            { value: "Item 2", text: "11-50 employees" },
                            { value: "Item 3", text: "51-200 employees" },
                            { value: "Item 4", text: "201-500 employees" },
                            { value: "Item 5", text: "501-1000 employees" },
                            { value: "Item 6", text: "1001-5000 employees" },
                            { value: "Item 7", text: "5001-10000 employees" },
                            { value: "Item 8", text: "10001-50000 employees" },
                            { value: "Item 9", text: "50000+ employees" },
                        ],
                        selectToRankEnabled: true,
                        defaultValue: prevData ? prevData.question4 : null,
                    },
                    {
                        type: "dropdown",
                        name: "question5",
                        title:
                            "What is the minimum salary range you would consider enough to interview?",
                        choices: [
                            { value: "Item 1", text: "60,000-80,000" },
                            { value: "Item 2", text: "80,000-100,000" },
                            { value: "Item 3", text: "100,000-125,000" },
                            { value: "Item 4", text: "125,000-150,000" },
                            { value: "Item 5", text: "150,000-175,000" },
                            { value: "Item 6", text: "175,000-200,000" },
                            { value: "Item 7", text: "200,000-250,000" },
                            { value: "Item 8", text: "250,000-300,000" },
                            { value: "Item 9", text: "300,000-400,000" },
                            { value: "Item 10", text: "400,000-500,000" },
                            { value: "Item 11", text: "500,000+" },
                        ],
                        defaultValue: prevData ? prevData.question5 : null,
                    },
                    {
                        type: "checkbox",
                        name: "question6",
                        title: "What are job levels you would interview for?",
                        choices: [
                            {
                                value: "Item 1",
                                text: "Entry (0-2 years, just starting)",
                            },
                            {
                                value: "Item 2",
                                text: "Standard (3-5 years, some experience)",
                            },
                            {
                                value: "Item 3",
                                text: "Senior (5+ years, managing team)",
                            },
                            {
                                value: "Item 4",
                                text: "Staff (10+ years, managing department)",
                            },
                            {
                                value: "Item 5",
                                text:
                                    "Principal (15+ years, managing departments)",
                            },
                            {
                                value: "Item 6",
                                text:
                                    "Distinguished (20+ years, managing organization)",
                            },
                        ],
                        selectToRankEnabled: true,
                        defaultValue: prevData ? prevData.question6 : null,
                    },
                    {
                        type: "ranking",
                        name: "question7",
                        title:
                            "Rank the following technologies in the order you'd want to work with them",
                        choices: [
                            { value: "Item 1", text: "Python" },
                            { value: "Item 2", text: "R" },
                            { value: "Item 3", text: "C++" },
                            { value: "Item 4", text: "Java" },
                            { value: "Item 5", text: "Javascript" },
                            { value: "Item 6", text: "HTML & CSS" },
                            {
                                value: "Item 7",
                                text: "Web Frameworks (Django, Flask)",
                            },
                            {
                                value: "Item 8",
                                text: "Distributed Compute (Spark, Ray, Dask)",
                            },
                            {
                                value: "Item 9",
                                text: "Cloud Services (AWS, Azure, GCP)",
                            },
                            {
                                value: "Item 10",
                                text:
                                    "ML Libraries (Scikit, PyTorch, TensorFlow)",
                            },
                            {
                                value: "Item 11",
                                text:
                                    "Scientific Compute Libraries (Numpy, Pandas/Polars)",
                            },
                            {
                                value: "Item 12",
                                text: "Databases (SQL, Cassandra, Kdb)",
                            },
                            {
                                value: "Item 13",
                                text: "Data Lakes (Delta Lake, Apache Hudi)",
                            },
                        ],
                        selectToRankEnabled: true,
                        defaultValue: prevData ? prevData.question7 : null,
                    },
                ],
            },
        ],
    });

    const handleSubmit = async (job_pref_survey, options) => {
        options.showSaveInProgress();
        const user_id = localStorage.getItem("user_id");

        try {
            const checkResponse = await axiosInterceptor.patch(
                `/save_or_update_user_surveys/${user_id}/`,
                { job_pref_survey }
            );

            console.log(checkResponse);

            if (checkResponse.status === 200) {
                options.showSaveSuccess();
                return checkResponse.data.results;
            } else {
                throw new Error("Survey not updated");
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                try {
                    const {
                        data,
                    } = await axiosInterceptor.post(
                        `/save_or_update_user_surveys/${user_id}/`,
                        { job_pref_survey }
                    );

                    options.showSaveSuccess();
                    return data.results;
                } catch (postError) {
                    console.error("Error submitting survey:", postError);
                    options.showSaveError();
                }
            } else {
                console.error("Error checking survey existence:", error);
                options.showSaveError();
            }
        }
    };

    if (!survey) {
        return <div>Loading survey...</div>;
    }

    return <Survey model={survey} />;
};

export default JobPreferencesSurvey;
