import React from "react";
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from "react-router-dom";

// Import CSS
import "./assets/css/bootstrap.custom.scss";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/slicknav.css";
import "./assets/css/flaticon.css";
import "./assets/css/gijgo.css";
import "./assets/css/animate.min.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/fontawesome-all.min.css";
import "./assets/css/themify-icons.css";
import "./assets/css/slick.css";
import "./assets/css/nice-select.css";
import "./assets/css/style.css";
import "./assets/loginAssets/css/main.css";
import "./assets/loginAssets/css/util.css";
import "./assets/registerAssets/css/util.css";
import "./assets/registerAssets/css/main.css";
import "./assets/lectureAssets/main.css";

import "./index.css";

// Import Templates
import RootTemplate from "./templates/RootTemplate";
import LectureTemplate from "./templates/LectureTemplate";
import ContentTemplate, { contentLoader } from "./templates/ContentTemplate";

// Import Pages
import RecoverForm from "./routes/account/RecoverForm";
import ResetForm from "./routes/account/ResetForm.jsx";
// import SignUp from "./routes/account/Signup";
import NewSignUp from "./routes/account/NewSignup";
import UserVerifyForm from "./routes/account/UserVerifyForm";
import NotFound from "./routes/error/NotFound";
import Success from "./routes/account/Success";
import PendingAlgolinkVerification from "./routes/account/PendingAlgolinkVerification.jsx";

import LoginForm from "./routes/account/LoginForm";
import TestCenter from "./routes/test_center/Home.jsx";
import { Behavioral, Background } from "./routes/test_center/Behavioral.jsx";
import Technical from "./routes/test_center/Technical.jsx";
import TechnicalHome from "./routes/test_center/TechnicalHome.jsx";
import Chat from "./routes/chat/Chat.jsx";
import AdminDashboard from "./routes/AdminDashboard.jsx";

import FAQ from "./routes/about/About";
import Courses from "./components/courses";
import Home from "./components/Home";
// import SelectCourses from "./components/personalized_workflow/SelectCourses";

// import PersonalSignUp from "./routes/account/Personal_Info";
// import CareerSignUp from "./routes/account/Career_Info";
import Lectures from "./routes/lectures/Lectures";
import Profile from "./routes/user/Profile.jsx";
import CourseOverview, {
    courseOverviewLoader,
} from "./components/CourseOverview";

import JobPreferencesSurvey from "./components/careers/JobPreferencesSurvey";
import PersonalitySurvey from "./components/careers/PersonalitySurvey.jsx";
import JobRatingApp from "./components/careers/JobRatingApp";
import RecruitmentPipelineManager from "./components/recruitment_pipeline/RecruitmentPipelineManager";

import CourseRecommender from "./routes/course_recommender/Home.jsx";
import TraditionalWorkflowHome from "./routes/course_recommender/TraditionalWorkflow.jsx";
import CourseOverviewMain from "./routes/course_recommender/CourseOverview.jsx";
import ClassOverview from "./routes/course_recommender/ClassOverview.jsx";

import TermsOfUse from "./terms-of-use/TermsOfUse.jsx";
import PrivacyPolicy from "./routes/privacy-policy/PrivacyPolicy.jsx";
import UseOfCookies from "./routes/use-of-cookies/UseOfCookies.jsx";

import { Error } from "./routes/error/Error";

//import private route component
import { VerificationRequiredRoute } from "./utils/VerificationRequiredRoute.jsx";
import SEOWrapper from "./components/SEOWrapper";

const router = createBrowserRouter([
    {
        path: "/",
        element: <RootTemplate />,
        children: [
            {
                path: "",
                element: (
                    <SEOWrapper
                        title="AI-Powered Career Development - AlgoLink"
                        description="AlgoLink offers AI-powered referrals, career development tools, and courses to help you advance in your tech or finance career."
                    >
                        {localStorage.getItem("access_token") &&
                        localStorage.getItem("is_email_verified") === "true" &&
                        localStorage.getItem("is_verified_by_algolink") ===
                            "true" ? (
                            <VerificationRequiredRoute
                                component={() => <Navigate to="/courses" />}
                            />
                        ) : (
                            <Home />
                        )}
                    </SEOWrapper>
                ),
            },
            {
                path: "about",
                element: (
                    <SEOWrapper
                        title="Our Mission and Vision - AlgoLink"
                        description="Learn about AlgoLink's mission to revolutionize tech education and career development through AI-powered solutions."
                    >
                        <VerificationRequiredRoute component={FAQ} />
                    </SEOWrapper>
                ),
            },
            {
                path: "chat",
                element: (
                    <SEOWrapper
                        title="Connect with Referrers - AlgoLink"
                        description="Talk with matched employees at big tech and quant firms to get advice on your resume, job search, and interviews."
                    >
                        <VerificationRequiredRoute component={Chat} />
                    </SEOWrapper>
                ),
            },
            {
                path: "management",
                element: (
                    <SEOWrapper
                        title="Admin Dashboard - AlgoLink"
                        description="Manage which applicants are let onto AlgoLink's platform to ensure that only the highest-quality candidates show up."
                    >
                        <VerificationRequiredRoute component={AdminDashboard} adminRequired={true} />
                    </SEOWrapper>
                ),
            },
            {
                path: "account",
                children: [
                    {
                        path: "login",
                        element: (
                            <SEOWrapper
                                title="Access Your Account - AlgoLink"
                                description="Sign in to your AlgoLink account here"
                            >
                                <VerificationRequiredRoute
                                    component={LoginForm}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "success",
                        element: (
                            <SEOWrapper
                                title="Account Creation Success - AlgoLink"
                                description="Your account has been successfully created. Start exploring our courses and career development tools."
                            >
                                <VerificationRequiredRoute
                                    component={Success}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "recover",
                        element: (
                            <SEOWrapper
                                title="Recover Your Account - AlgoLink"
                                description="Recover your AlgoLink account by following the instructions sent to your email."
                            >
                                <RecoverForm />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "reset",
                        element: (
                            <SEOWrapper
                                title="Reset Your Password - AlgoLink"
                                description="Reset your AlgoLink password to secure your account and access your account"
                            >
                                <ResetForm />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "signup",
                        element: (
                            <SEOWrapper
                                title="Kickstart Your Career - AlgoLink"
                                description="Create your AlgoLink account and begin your personalized, referral-based career development journey today."
                            >
                                <VerificationRequiredRoute
                                    component={NewSignUp}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "verify",
                        element: (
                            <SEOWrapper
                                title="Verify Your Email - AlgoLink"
                                description="Verify your email address to complete your AlgoLink account registration and access our tools."
                            >
                                <UserVerifyForm />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "pending-algolink-verification",
                        element: (
                            <SEOWrapper
                                title="Pending Verification - AlgoLink"
                                description="Your AlgoLink verification is pending. Please wait for our team to review your application."
                            >
                                <VerificationRequiredRoute
                                    emailSuffices={true}
                                    component={PendingAlgolinkVerification}
                                />
                            </SEOWrapper>
                        ),
                    },
                ],
            },
            {
                path: "courses",
                children: [
                    {
                        path: "",
                        element: (
                            <SEOWrapper
                                title="Advance Your Skills - AlgoLink"
                                description="Explore our wide range of MIT-based courses across math, finance, and CS designed to enhance your skills and advance your career."
                            >
                                <VerificationRequiredRoute
                                    component={Courses}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: ":courseId",
                        element: (
                            <SEOWrapper
                                title="Course Overview - AlgoLink"
                                description="Get an overview of the course, including its syllabus, instructor, and learning objectives."
                            >
                                <VerificationRequiredRoute
                                    component={CourseOverview}
                                />
                            </SEOWrapper>
                        ),
                        loader: courseOverviewLoader,
                        shouldRevalidate: () => false,
                        errorElement: <Error />,
                        children: [
                            {
                                path: "lectures",
                                element: (
                                    <SEOWrapper
                                        title="Course Lectures - AlgoLink"
                                        description="Access and navigate through course lectures and learning materials."
                                    >
                                        <VerificationRequiredRoute
                                            component={LectureTemplate}
                                        />
                                    </SEOWrapper>
                                ),
                                shouldRevalidate: () => false,
                                errorElement: <Error />,
                                children: [
                                    {
                                        path: "",
                                        element: (
                                            <SEOWrapper
                                                title="Browse Lectures - AlgoLink"
                                                description="Browse and select the lectures you want to watch and learn from."
                                            >
                                                <VerificationRequiredRoute
                                                    component={Lectures}
                                                />
                                            </SEOWrapper>
                                        ),
                                    },
                                    {
                                        path: ":lessonId",
                                        element: (
                                            <SEOWrapper
                                                title="Lecture Content - AlgoLink"
                                                description="Access the lecture content, including videos, transcripts, and resources."
                                            >
                                                <VerificationRequiredRoute
                                                    component={ContentTemplate}
                                                />
                                            </SEOWrapper>
                                        ),
                                        loader: contentLoader,
                                        errorElement: <Error />,
                                        children: [
                                            {
                                                path: "content",
                                                element: (
                                                    <SEOWrapper
                                                        title="Lecture Video - AlgoLink"
                                                        description="Watch the lecture video and enhance your learning experience."
                                                    >
                                                        <div>Content</div>
                                                    </SEOWrapper>
                                                ),
                                            },
                                            {
                                                path: "transcript",
                                                element: (
                                                    <SEOWrapper
                                                        title="Lecture Transcript - AlgoLink"
                                                        description="Read the lecture transcript to follow along with the video or review the content."
                                                    >
                                                        <div>Transcript</div>
                                                    </SEOWrapper>
                                                ),
                                            },
                                            {
                                                path: "resources",
                                                element: (
                                                    <SEOWrapper
                                                        title="Lecture Resources - AlgoLink"
                                                        description="Access additional resources related to the lecture for further learning and reference."
                                                    >
                                                        <div>Resources</div>
                                                    </SEOWrapper>
                                                ),
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: "course-recommender",
                children: [
                    {
                        path: "",
                        element: (
                            <SEOWrapper
                                title="Find Your Perfect Course - AlgoLink"
                                description="Get personalized course recommendations to show off to recruiters based on your skills, goals, and career aspirations."
                            >
                                <VerificationRequiredRoute
                                    component={CourseRecommender}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "traditional-workflow",
                        element: (
                            <SEOWrapper
                                title="Traditional Course Workflow - AlgoLink"
                                description="Explore our traditional course workflow and find the right course for you."
                            >
                                <VerificationRequiredRoute
                                    component={TraditionalWorkflowHome}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "personalized-workflow",
                        element: (
                            <SEOWrapper
                                title="Personalized Course Workflow - AlgoLink"
                                description="Discover our personalized course workflow tailored to your learning style and goals."
                            >
                                <VerificationRequiredRoute
                                    component={TraditionalWorkflowHome}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "speed-review",
                        element: (
                            <SEOWrapper
                                title="Speed Review Courses - AlgoLink"
                                description="Review course content quickly and efficiently to save time and maximize learning."
                            >
                                <VerificationRequiredRoute
                                    component={TraditionalWorkflowHome}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "course-overview",
                        element: (
                            <SEOWrapper
                                title="Recommended Course Path Overview - AlgoLink"
                                description="Get a comprehensive overview of a recommended course path, including your progress and the individual courses making it up."
                            >
                                <VerificationRequiredRoute
                                    component={CourseOverviewMain}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "class-overview",
                        element: (
                            <SEOWrapper
                                title="Class Overview - AlgoLink"
                                description="Explore the class overview UI, including course materials and lessons."
                            >
                                <VerificationRequiredRoute
                                    component={ClassOverview}
                                />
                            </SEOWrapper>
                        ),
                    },
                ],
            },
            {
                path: "job-preferences-survey",
                element: (
                    <SEOWrapper
                        title="Customize Your Career Path - AlgoLink"
                        description="Take our job preferences survey to help us tailor your referrals, career development journey, and course recommendations."
                    >
                        <VerificationRequiredRoute
                            component={JobPreferencesSurvey}
                        />
                    </SEOWrapper>
                ),
            },
            {
                path: "personality-survey",
                element: (
                    <SEOWrapper
                        title="Discover Your Professional Strengths - AlgoLink"
                        description="Complete our personality survey to help us gain insights into your professional strengths and ideal work environment."
                    >
                        <VerificationRequiredRoute
                            component={PersonalitySurvey}
                        />
                    </SEOWrapper>
                ),
            },
            {
                path: "job-finder",
                element: (
                    <SEOWrapper
                        title="Discover Your Next Tech Opportunity - AlgoLink"
                        description="Use our AI-powered job finder to discover and rate tech job opportunities tailored to your skills and preferences."
                    >
                        <VerificationRequiredRoute component={JobRatingApp} />
                    </SEOWrapper>
                ),
            },
            {
                path: "progress",
                element: (
                    <SEOWrapper
                        title="Track Your Career Growth - AlgoLink"
                        description="Monitor your career progress and application statuses with AlgoLink's comprehensive tracking system."
                    >
                        <VerificationRequiredRoute
                            component={RecruitmentPipelineManager}
                        />
                    </SEOWrapper>
                ),
            },
            {
                path: "profile",
                element: (
                    <SEOWrapper
                        title="Manage Your Account - AlgoLink"
                        description="View and update your AlgoLink profile and manage your account settings here."
                    >
                        <VerificationRequiredRoute component={Profile} />
                    </SEOWrapper>
                ),
            },
            {
                path: "test-center",
                children: [
                    {
                        path: "home",
                        element: (
                            <SEOWrapper
                                title="Assess Your Skills - AlgoLink"
                                description="Take various tech assessments to gauge your skills and identify areas for improvement."
                            >
                                <VerificationRequiredRoute
                                    component={TestCenter}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "technical-home",
                        element: (
                            <SEOWrapper
                                title="Technical Test Center - AlgoLink"
                                description="Assess your technical skills with our comprehensive technical test center."
                            >
                                <VerificationRequiredRoute
                                    component={TechnicalHome}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "technical",
                        element: (
                            <SEOWrapper
                                title="Technical Assessment - AlgoLink"
                                description="Test your technical knowledge and skills with our interactive technical assessments."
                            >
                                <VerificationRequiredRoute
                                    component={Technical}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "behavioral",
                        element: (
                            <SEOWrapper
                                title="Behavioral Assessment - AlgoLink"
                                description="Assess your behavioral skills and qualities with our behavioral test questions."
                            >
                                <VerificationRequiredRoute
                                    component={Behavioral}
                                />
                            </SEOWrapper>
                        ),
                    },
                    {
                        path: "background",
                        element: (
                            <SEOWrapper
                                title="Background Assessment - AlgoLink"
                                description="Test your background knowledge and understanding with our background assessment."
                            >
                                <VerificationRequiredRoute
                                    component={Background}
                                />
                            </SEOWrapper>
                        ),
                    },
                ],
            },
            {
                path: "terms-of-use",
                element: (
                    <SEOWrapper
                        title="Terms of Use - AlgoLink"
                        description="Read and understand AlgoLink's terms of use and service agreement."
                    >
                        <TermsOfUse />
                    </SEOWrapper>
                ),
            },
            {
                path: "privacy-policy",
                element: (
                    <SEOWrapper
                        title="Privacy Policy - AlgoLink"
                        description="Learn about how AlgoLink collects, uses, and protects your personal information."
                    >
                        <PrivacyPolicy />
                    </SEOWrapper>
                ),
            },
            {
                path: "use-of-cookies",
                element: (
                    <SEOWrapper
                        title="Use of Cookies - AlgoLink"
                        description="Understand how AlgoLink uses cookies to enhance your browsing experience and improve our services."
                    >
                        <UseOfCookies />
                    </SEOWrapper>
                ),
            },
            {
                path: "*",
                element: (
                    <SEOWrapper
                        title="Page Not Found - AlgoLink"
                        description="The page you're looking for doesn't exist. Navigate back to AlgoLink's homepage or explore our courses."
                    >
                        <NotFound />
                    </SEOWrapper>
                ),
            },
        ],
    },
]);

function App() {
    return (
        <div className="App" style={{ backgroundColor: "#ffffff" }}>

            <RouterProvider router={router} />
        </div>
    );
}

export default App;
