import React from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";
import { Link } from "react-router-dom";

function ResetForm(props) {
    const [password, setPassword] = React.useState({
        1: "",
        2: "",
    });
    const [validated, setValidated] = React.useState(false);
    const [inSecondStage, setInSecondStage] = React.useState(false);

    function handleChange(e) {
        if (e.target.name === "2") {
            if (e.target.value !== password[1]) {
                e.target.setCustomValidity("passwords do not match");
            } else {
                e.target.setCustomValidity("");
            }
        }
        setPassword({ ...password, [e.target.name]: e.target.value });
    }

    async function handleSubmit(e) {
        e.preventDefault();
        if (e.currentTarget.checkValidity()) {
            try {
                await axiosInterceptor.put("/attempt_password_reset/", {
                    password: password[1],
                    token: new URLSearchParams(window.location.search).get(
                        "token"
                    ),
                });
                setInSecondStage(true);
            } catch (err) {
                console.error(err.response);
                alert(err.response.data.error);
            }
        } else if (!validated) setValidated(true);
        // print why invalid
    }

    return !inSecondStage ? (
        <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="max-w-2xl mx-auto flex flex-col gap-y-2 w-[370px] h-[80vh] justify-center -mt-4"
        >
            <h2 className="text-2xl !font-normal text-left mb-3.5">
                Reset Password
            </h2>
            <p className="text-sm !font-normal mb-2">
                Please ensure that your password is relatively complex.
            </p>
            <FloatingLabel label="New password">
                <Form.Control
                    name="1"
                    type="password"
                    // pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_\-])[A-Za-z\d@$!%*?&_\-]{8,}$"
                    placeholder=" "
                    required
                    value={password[1]}
                    onChange={handleChange}
                />
            </FloatingLabel>
            <FloatingLabel label="Confirm new password">
                <Form.Control
                    name="2"
                    type="password"
                    // pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_\-])[A-Za-z\d@$!%*?&_\-]{8,}$"
                    placeholder=" "
                    required
                    value={password[2]}
                    onChange={handleChange}
                />
            </FloatingLabel>
            <Button
                className="bg-[#1B4E99] text-white focus:bg-[#1B4E99] hover:bg-[#1B4E99] border-[#1B4E99] hover:border-[#1B4E99] font-medium text-sm px-4 py-2 rounded-lg shadow-sm mt-2.5"
                type="submit"
                disabled={password[1] !== password[2] || !password[1]}
            >
                Reset
            </Button>
        </Form>
    ) : (
        <div className="max-w-2xl mx-auto flex flex-col gap-y-2 w-[370px] h-[80vh] justify-center -mt-4 text-center">
            <h2 className="text-2xl !font-normal mb-3.5">
                Password Reset Successful
            </h2>
            <p className="text-black font-light">
                Your password has been successfully updated. You can now log in
                using your new password.
            </p>
            <Button
                className="text-[#1B4E99] bg-white border-2 focus:text-[#1B4E99] hover:text-[#1B4E99] border-[#1B4E99] hover:border-[#1B4E99] font-medium text-sm px-4 py-2 rounded-md shadow-sm mt-2.5"
                href="/account/login"
                as={Link}
                onClick={() => {
                    window.location.href = "/account/login";
                }}
            >
                Back to Login
            </Button>
        </div>
    );
}

export default ResetForm;
