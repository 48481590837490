import React, { useState, useEffect } from "react";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";

const PersonalitySurvey = () => {
    const [survey, setSurvey] = useState(null);

    useEffect(() => {
        const initializeSurvey = async () => {
            const prevData = await getPrevData();
            const surveyJson = createSurveyJson(prevData);
            const newSurvey = new Model(surveyJson);
            newSurvey.onComplete.add(handleSubmit);
            newSurvey.completedHtml =
                "Your job preferences were submitted successfully";
            setSurvey(newSurvey);
        };

        initializeSurvey();
    }, []);

    const getPrevData = async () => {
        try {
            const user_id = localStorage.getItem("user_id");

            if (user_id) {
                const response = await axiosInterceptor.get(
                    `/save_or_update_user_surveys/${user_id}/`
                );
                const data = response.data;

                if (data && data.personality_survey) {
                    return JSON.parse(data.personality_survey);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        return null;
    };

    const createSurveyJson = (prevData) => ({
        title: "Personality Test",
        description: "",
        logoPosition: "right",
        pages: [
            {
                name: "page1",
                elements: [
                    {
                        type: "rating",
                        name: "question1",
                        title: "I dislike/enjoy competing with others",
                        minRateDescription: "Dislike",
                        maxRateDescription: "Enjoy",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question1 : null,
                    },
                    {
                        type: "rating",
                        name: "question2",
                        title:
                            "I work best in a relaxed/fast-paced work environment",
                        minRateDescription: "Relaxed",
                        maxRateDescription: "Fast-paced",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question2 : null,
                    },
                    {
                        type: "rating",
                        name: "question3",
                        title: "I try to do things as my manager asks",
                        minRateDescription: "Least like me",
                        maxRateDescription: "Most like me",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question3 : null,
                    },
                    {
                        type: "rating",
                        name: "question4",
                        title:
                            "It is best to tell the complete and honest truth, even if it hurts someone's feelings",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question4 : null,
                    },
                    {
                        type: "rating",
                        name: "question5",
                        title:
                            "People cannot always expect others to help them finish their work",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question5 : null,
                    },
                    {
                        type: "rating",
                        name: "question6",
                        title: "People come to me for a positive point of view",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question6 : null,
                    },
                    {
                        type: "rating",
                        name: "question7",
                        title: "Success is what drives me in my work",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question7 : null,
                    },
                    {
                        type: "rating",
                        name: "question8",
                        title: "I always do more than what is required at work",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question8 : null,
                    },
                    {
                        type: "rating",
                        name: "question9",
                        title:
                            "Most people look out for the well being of others",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question9 : null,
                    },
                    {
                        type: "rating",
                        name: "question10",
                        title:
                            "It is critical to take the time to ensure the accuracy of my work",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question10 : null,
                    },
                    {
                        type: "rating",
                        name: "question11",
                        title: "I have never made mistakes at work",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question11 : null,
                    },
                    {
                        type: "rating",
                        name: "question12",
                        title: "I enjoy competing with others",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question12 : null,
                    },
                    {
                        type: "rating",
                        name: "question13",
                        title:
                            "I work efficiently in highly stressful situations",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question13 : null,
                    },
                    {
                        type: "rating",
                        name: "question14",
                        title: "I enjoy fast-paced hectic days",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question14 : null,
                    },
                    {
                        type: "rating",
                        name: "question15",
                        title: "I try to do things my boss wants",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question15 : null,
                    },
                    {
                        type: "rating",
                        name: "question16",
                        title: "I strictly follow rules even when others don't",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question16 : null,
                    },
                    {
                        type: "rating",
                        name: "question17",
                        title:
                            "I frequently spend time helping colleagues with their work",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question17 : null,
                    },
                    {
                        type: "rating",
                        name: "question18",
                        title: "I always look on the bright side of things",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question18 : null,
                    },
                    {
                        type: "rating",
                        name: "question19",
                        title:
                            "I prefer goals that push me to the limits of my ability",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question19 : null,
                    },
                    {
                        type: "rating",
                        name: "question20",
                        title:
                            "I have full confidence in my ability to succeed",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question20 : null,
                    },
                    {
                        type: "rating",
                        name: "question21",
                        title: "I always go beyond my job duties at work",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question21 : null,
                    },
                    {
                        type: "rating",
                        name: "question22",
                        title: "Most people treat others fairly and honestly",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question22 : null,
                    },
                    {
                        type: "rating",
                        name: "question23",
                        title:
                            "Work should be as perfect as possible the first time around",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question23 : null,
                    },
                    {
                        type: "rating",
                        name: "question24",
                        title: "I always treat people fairly",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question24 : null,
                    },
                    {
                        type: "rating",
                        name: "question25",
                        title: "I look forward to competitive situations",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question25 : null,
                    },
                    {
                        type: "rating",
                        name: "question26",
                        title: "I enjoy working under pressure",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question26 : null,
                    },
                    {
                        type: "rating",
                        name: "question27",
                        title: "I tend to be happier when I am busy at work",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question27 : null,
                    },
                    {
                        type: "rating",
                        name: "question28",
                        title:
                            "Roles exist for a purpose and I feel obligated to follow them",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question28 : null,
                    },
                    {
                        type: "rating",
                        name: "question29",
                        title:
                            "I often sacrifice my own time to help others at work",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question29 : null,
                    },
                    {
                        type: "rating",
                        name: "question30",
                        title: "I tend to find the upside that others overlook",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question30 : null,
                    },
                    {
                        type: "rating",
                        name: "question31",
                        title: "I am motivated by extremely challenging goals",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question31 : null,
                    },
                    {
                        type: "rating",
                        name: "question32",
                        title: "I can handle whatever comes my way",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question32 : null,
                    },
                    {
                        type: "rating",
                        name: "question33",
                        title:
                            "I should do more than what is asked of me at work",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question33 : null,
                    },
                    {
                        type: "rating",
                        name: "question34",
                        title: "I am precise in my work",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question34 : null,
                    },
                    {
                        type: "rating",
                        name: "question35",
                        title: "I'm never rude to other people",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question35 : null,
                    },
                    {
                        type: "rating",
                        name: "question36",
                        title: "I focus on finishing what I need to get done",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question36 : null,
                    },
                    {
                        type: "rating",
                        name: "question37",
                        title:
                            "I set very high performance standards for myself",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question37 : null,
                    },
                    {
                        type: "rating",
                        name: "question38",
                        title:
                            "I am fully confident in my ability to tackle difficult problems",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question38 : null,
                    },
                    {
                        type: "rating",
                        name: "question39",
                        title: "Most people can be trusted",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question39 : null,
                    },
                    {
                        type: "rating",
                        name: "question40",
                        title: "I never talk behind peoples' backs",
                        minRateDescription: "Strongly disagree",
                        maxRateDescription: "Strongly agree",
                        isRequired: true,
                        defaultValue: prevData ? prevData.question40 : null,
                    },
                ],
            },
        ],
    });

    const handleSubmit = async (sender, options) => {
        options.showSaveInProgress();
        const user_id = localStorage.getItem("user_id");
        const personality_survey = sender.data;

        try {
            const checkResponse = await axiosInterceptor.patch(
                `/save_or_update_user_surveys/${user_id}/`,
                { personality_survey }
            );

            if (checkResponse.status === 200) {
                options.showSaveSuccess();
                return checkResponse.data.results;
            } else {
                throw new Error("Survey not updated");
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                try {
                    const {
                        data,
                    } = await axiosInterceptor.post(
                        `/save_or_update_user_surveys/${user_id}/`,
                        { personality_survey }
                    );

                    options.showSaveSuccess();
                    return data.results;
                } catch (postError) {
                    console.error("Error submitting survey:", postError);
                    options.showSaveError();
                }
            } else {
                console.error("Error checking survey existence:", error);
                options.showSaveError();
            }
        }
    };

    if (!survey) {
        return <div>Loading survey...</div>;
    }

    return <Survey model={survey} />;
};

export default PersonalitySurvey;
