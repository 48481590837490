import React, { useEffect, useState } from "react";
import { Link, Outlet, useLoaderData, useLocation } from "react-router-dom";
import "../assets/courseAssets/css/main.css";
import "../assets/courseAssets/css/main.scss";
import ScrollContainer from "react-indiana-drag-scroll";
import { fetchJsonData } from "./fileUtils";
import { Accordion, Button } from "react-bootstrap";
import { CheckCircle } from "react-bootstrap-icons";
import SEOWrapper from "./SEOWrapper";
import axiosInterceptor from "../utils/axiosInterceptor.ts";

function extractKeyFromFileName(fileName) {
    const parts = fileName.split("_");
    const key = parts
        .slice(0, 2)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    return key;
}

function extractNumberFromFileName(fileName) {
    const regex = /(\d+)$/;
    const match = regex.exec(fileName);
    if (match && match[1]) {
        return match[1];
    } else {
        return null;
    }
}

export const StackedDropdowns = ({
    content,
    accumulatedLength,
    completedLectures,
    toggleLectureCompletion,
    openFirstAccordion = false,
}) => {
    return (
        <Accordion
            className="w-full flex flex-col space-y-4 !overflow-y-scroll"
            alwaysOpen
            defaultActiveKey={openFirstAccordion ? "4" : "-1"}
        >
            {Object.keys(content).map((key, i) => {
                if (
                    key !== "id" &&
                    key !== "title" &&
                    key !== "author" &&
                    key !== "term"
                ) {
                    const fileList = content[key];
                    const fileListLength = fileList.length;
                    const startIndex = accumulatedLength;
                    accumulatedLength += fileListLength;
                    fileList.sort((a, b) => {
                        const numberA = parseInt(a.match(/(\d+)/)[0], 10);
                        const numberB = parseInt(b.match(/(\d+)/)[0], 10);
                        return numberA - numberB;
                    });
                    return (
                        <Accordion.Item
                            eventKey={i.toString()}
                            key={i}
                            className="w-full !border-none"
                        >
                            <Accordion.Header
                                className="bg-accordion-gray flex flex-col items-start half-accordion-round"
                                onClick={(e) => e.target.blur()}
                            >
                                <div className="flex flex-col">
                                    <p className="font-normal text-black block">
                                        {key}
                                    </p>
                                    <div className="mt-0.5 px-2 py-0.5 text-sm rounded-full bg-white w-min block font-medium text-nowrap">
                                        {
                                            fileList.filter((file) =>
                                                completedLectures.includes(
                                                    `${key}_${extractNumberFromFileName(
                                                        file
                                                    )}`
                                                )
                                            ).length
                                        }{" "}
                                        / {fileList.length}
                                    </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body className="!visible w-full !bg-white !rounded-b-lg !p-0">
                                <ScrollContainer
                                    vertical
                                    hideScrollbars={false}
                                    className="max-h-[400px] overflow-y-auto flex flex-col !rounded-b-lg"
                                >
                                    {fileList.map((file, j) => {
                                        const lectureId = `${key}_${extractNumberFromFileName(
                                            file
                                        )}`;
                                        const isCompleted = completedLectures.includes(
                                            lectureId
                                        );
                                        return (
                                            <div
                                                key={j}
                                                className={`font-light !no-underline !text-base flex flex-row justify-between py-2 px-4 items-center ${
                                                    window.location.pathname.includes(
                                                        "lectures/" +
                                                            (
                                                                startIndex +
                                                                j +
                                                                1
                                                            ).toString()
                                                    )
                                                        ? "bg-[#1868B4] bg-opacity-10"
                                                        : "bg-white"
                                                }`}
                                            >
                                                <Link
                                                    to={`lectures/${startIndex +
                                                        j +
                                                        1}`}
                                                    className="flex-grow"
                                                >
                                                    <span className="place-self-start self-start ml-0 mr-auto">{`${key
                                                        .split(" ")
                                                        .slice(-1)[0]
                                                        .slice(
                                                            0,
                                                            -1
                                                        )} ${extractNumberFromFileName(
                                                        file
                                                    )}`}</span>
                                                </Link>
                                                <CheckCircle
                                                    className={
                                                        (isCompleted
                                                            ? "bg-green-400"
                                                            : "bg-gray-400") +
                                                        " text-white rounded-full size-5 cursor-pointer"
                                                    }
                                                    onClick={() =>
                                                        toggleLectureCompletion(
                                                            lectureId
                                                        )
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                </ScrollContainer>
                            </Accordion.Body>
                        </Accordion.Item>
                    );
                }
                return null;
            })}
        </Accordion>
    );
};

export default function CourseOverview() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const content = useLoaderData();
    const location = useLocation();
    const path = location.pathname.split("/");
    const [completedLectures, setCompletedLectures] = useState([]);

    useEffect(() => {
        const fetchCompletedLectures = async () => {
            try {
                const response = await axiosInterceptor.get(
                    `/get-completed-lectures-for-course/${content.id.replaceAll(
                        " ",
                        "-"
                    )}/`
                );
                setCompletedLectures(
                    response.data.map((lecture) => lecture.lecture)
                );
            } catch (error) {
                console.error("Error fetching completed lectures:", error);
            }
        };
        fetchCompletedLectures();
    }, [content.id]);

    const toggleLectureCompletion = async (lectureId) => {
        try {
            await axiosInterceptor.post(
                `/toggle-lecture-completion-for-course/${content.id.replaceAll(
                    " ",
                    "-"
                )}/`,
                {
                    lecture: lectureId,
                }
            );
            setCompletedLectures((prev) =>
                prev.includes(lectureId)
                    ? prev.filter((id) => id !== lectureId)
                    : [...prev, lectureId]
            );
        } catch (error) {
            console.error("Error toggling lecture completion:", error);
        }
    };

    let accumulatedLength = 0;

    const headerButtons = ["Content", "Resources"];

    return (
        <SEOWrapper
            title={`${content.title} - AlgoLink Course Overview`}
            description={`Explore the ${content.title} course by ${content.author}. Learn about ${content.title} through MIT OpenCourseWare content on AlgoLink.`}
        >
            <div className="flex flex-col gap-y-10 flex-grow">
                <div className="flex flex-col justify-between items-start gap-2">
                    <h1 className="text-3xl font-medium text-left">
                        {content.title}
                    </h1>
                    <div className="text-xs text-gray-500 w-full lg:w-5/12">
                        {content.author}. {content.title}. {content.term}.
                        Massachusetts Institute of Technology: MIT
                        OpenCouseWare, https://ocw.mit.edu/. License: Creative
                        Commons BY-NC-SA.
                    </div>
                </div>
                <div className="lg:grid grid-cols-5 gap-x-10">
                    <div className="hidden lg:block overflow-y-auto pr-4 lg:-mr-20 h-[calc(100vh-200px)]">
                        <StackedDropdowns
                            content={content}
                            accumulatedLength={accumulatedLength}
                            completedLectures={completedLectures}
                            toggleLectureCompletion={toggleLectureCompletion}
                            openFirstAccordion={true}
                        />
                    </div>
                    <div className="block lg:hidden overflow-y-auto mb-4 -mt-4">
                        <StackedDropdowns
                            content={content}
                            accumulatedLength={accumulatedLength}
                            completedLectures={completedLectures}
                            toggleLectureCompletion={toggleLectureCompletion}
                        />
                    </div>
                    <div className="lg:ml-10 col-span-4 flex flex-col gap-y-4">
                        <div className="w-full lg:w-1/2 flex flex-row gap-x-4">
                            {headerButtons.map((button, i) => (
                                <Button
                                    key={i}
                                    variant="outline-secondary"
                                    disabled={path.length < 5}
                                    href={`${
                                        path.length === 6
                                            ? path.slice(0, 5).join("/")
                                            : path.join("/")
                                    }/${button.toLowerCase()}`}
                                    className={
                                        "text-center hover:!bg-[#d9d9d9] !no-underline !text-base !border hover:!border border-gray-400 hover:!text-gray-600 text-black rounded-xl " +
                                        (path.length < 5
                                            ? "cursor-not-allowed"
                                            : "")
                                    }
                                >
                                    {button}
                                </Button>
                            ))}
                        </div>
                        <div
                            className="min-w-full bg-[#d9d9d9] rounded-lg overflow-hidden shadow"
                            style={{
                                height: `calc(100vh - 260px)`,
                            }}
                        >
                            {path.includes("lectures") ? <Outlet /> : null}
                        </div>
                    </div>
                </div>
            </div>
        </SEOWrapper>
    );
}

export const courseOverviewLoader = async ({ params }) => {
    const id = params.courseId;
    const file = id + ".json";
    const serverPath = "generated/" + file; // Replace with your actual server path
    const dataObject = await fetch(
        `https://ai-academy-storage-f951b86a124629-staging.s3.amazonaws.com/public/static/generated/${encodeURIComponent(
            file
        )}`
    );
    // const data = await fetchDataFromUrl(serverPath, 'json');
    const data = await fetchJsonData(dataObject.url);
    const authorAndTermObject = await fetch(
        `https://ai-academy-storage-f951b86a124629-staging.s3.amazonaws.com/public/static/citations/citations.json`
    );
    // const authorAndTerm = await fetchDataFromUrl('citations/citations.json', 'json');
    const authorAndTerm = await fetchJsonData(authorAndTermObject.url);
    const content = {
        id: id,
        title: id.replace(/-/g, " "),
        author: authorAndTerm[id]["author"],
        term: authorAndTerm[id]["term"],
    };
    for (let i = 0; i < data.nonEmptyDirectories.length; i++) {
        const fileList = data.filesInNonEmptyDirectories[i];
        const key = extractKeyFromFileName(fileList[0]); // Use the first file for key extraction
        content[key] = fileList;
    }
    return content;
};
