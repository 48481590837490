import React from "react";
import axiosInterceptor from "../../../utils/axiosInterceptor.ts";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { Row, Modal } from 'react-bootstrap'
import FormInput from "../../FormInput";


export default function ExternalCourse({ courseList }) {
  function CourseItem({ key, item }) {
    const { school, degree, course_name, department } = item;
      return (
        <div className="flex flex-row items-center justify-between mb-3">
          <div className="flex flex-col gap-y-0.5">
            <p className="font-semibold text-black text-lg">{course_name}</p>
            <p className="text-black">{department}</p>
            <p className="text-gray-700">Associated with {school}</p>
          </div>
        </div>
      );
  }

  return (
    <div className="-mb-3">
      {courseList.map((item, i) => {
        return <CourseItem key={i} item={item} />;
      })}
    </div>
  );
}


export function CreateCourse({ courseList, setCourse, userEds }) {
  var default_edu_name = null
  var default_edu_degree = null
  if (userEds.length > 0){
    default_edu_name = userEds[0].school
    default_edu_degree = userEds[0].degree
  }
  const BLANK_DATA = {
    school: default_edu_name,
    degree: default_edu_degree,
    course_name: "",
    department: "",
  };

  var start_info = BLANK_DATA

  if (courseList != null && JSON.stringify(courseList) !== "{}"){
    start_info = courseList
  }
  
  const [courses, setCourses] = React.useState(start_info);

  function updateCourseItem(index, field, value) {
    // Create a copy of the eduList
    const updatedList = [...courses];

    // Update the specified item with the new values
    updatedList[index] = {
      ...updatedList[index],
      [field]: value,
    };
    // Update the eduList state
    setCourses(updatedList);
    console.log('updateCourseItem',courses);
    console.log('userEds',userEds);
  }

  const handleChange = function (e, index) {
      updateCourseItem(index, e.target.name, e.target.value)
  };

  function handleRemove(itemToRemove) {
    console.log('itemtoremove:', itemToRemove)
    if('id' in itemToRemove){
      console.log('has id')
      try{
        const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
        axiosInterceptor.delete(`/delete_course_entry/`+itemToRemove.id+'/', itemToRemove, {
          headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken,
              }
          });
      }
      catch(error){
        console.log('Error: ', error)
      }
    }
    const updateList = courses.filter(item => item !== itemToRemove);
    setCourses(updateList)
    console.log('courses after remove', courses);
  }

  function addNewEntry() {
    const updatedList = [...courses, BLANK_DATA]
    setCourses(updatedList)
    console.log('addNewEntry',courses);
  }

  const [showModal, setShowModal] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState('');

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (message) => {
    setModalMessage(message);
    setShowModal(true);
  };

  function validateFields() {
    for (const exp of courses) {
      for (const key in BLANK_DATA) {
        if (exp[key] == "") {
          handleShowModal(`The field ${key} is required.`);
          return false;
        }
      }
    }
    return true;
  }

  const handleSubmit = (event) =>{
    const user_id = localStorage.getItem('user_id')
    console.log('data to submit',courses)

    if(!validateFields()){
      return;
    }
    
    try {
    const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
    courses.forEach(course => {
      if('id' in course){
        axiosInterceptor.patch(`/update_course/`+course.id+'/', course, {
          headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken,
              }
          });
      }
      else{
        let edu_id = null;
        userEds.forEach(ed => { 
          if(ed['school'] == course.school && ed['degree'] == course.degree){
            edu_id = ed.id
          }
        });
          axiosInterceptor.post(`/create_course/`+edu_id+'/', course, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                }
            });
      }
    });

    } catch(error) { console.log('Error:', error)}
    window.location.reload();
  }

//{edu.education.school+" "+"("+edu.education.degree+")"}
  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {courses.map((item, index) => (
        <div key={index}>
          {/* Display each education entry */}
          <Form>
            
            <Stack gap={2}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ flex: 3 }}> 
                <Form.Group controlId="formDropdown">
                    <Form.Control 
                    as="select"
                    name="school"
                    value={item.school}
                    onChange={(e) => handleChange(e, index)}
                    >
                        {userEds.map((edu, index) => 
                        <option value = {edu.school}> {edu.school} </option>)}
                    </Form.Control>
                </Form.Group>
                </div>
                <div style={{ flex: 1 }}> 
                <Form.Group controlId="formDropdown">
                      <Form.Control 
                      as="select"
                      name="degree"
                      value={item.degree}
                      onChange={(e) => handleChange(e, index)}
                      >
                          {userEds.map((edu, index) => 
                          <option value = {edu.degree}> {edu.degree} </option>)}
                      </Form.Control>
                  </Form.Group>
                  </div>
                </div>
              <FormInput
                name="course_name"
                label="Name"
                type="text"
                value={item.course_name}
                handler={(e) => handleChange(e, index)}
                required
              />

              <FormInput
                name="department"
                label="department"
                type="text"
                value={item.department}
                handler={(e) => handleChange(e, index)}
                required
              />

              <Container style={{ textAlign: "center" }}>
                <p className="d-inline-block float-right p-0">
                  <Button onClick={() => handleRemove(item)} style={{ padding: '16px', fontSize: '8px' }}> Remove </Button>
                </p>
              </Container>
              <h2>    </h2>
            </Stack>
          </Form>
        </div>
      ))}
      <Container className="d-flex justify-content-center align-items-center">
        <Row>
          {/* <Col className="text-center"> */}
            <Button onClick = {addNewEntry} style={{ marginBottom: '25px' }}>Add New</Button>
            <Button onClick={handleSubmit}>Done</Button>
          {/* </Col> */}
        </Row>
      </Container>
    </div>
  );
}
