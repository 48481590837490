import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import "react-bootstrap-typeahead/css/Typeahead.css";

import { Typeahead } from "react-bootstrap-typeahead";
import axiosInterceptor from "../../../utils/axiosInterceptor.ts";

import possibleSkills from "../../../lib/skills.ts";
import { Button } from "react-bootstrap";

export default function Skills({ skills }) {
    return (
        <div className="flex flex-wrap flex-row gap-2">
            {skills.map((skill, i) => {
                if (typeof skill === "object") skill = skill.name;
                return (
                    <button
                        className="bg-[#ADC4FF] bg-opacity-20 text-black rounded py-1 px-2.5 w-auto text-sm"
                        key={i}
                    >
                        {skill}
                    </button>
                );
            })}
        </div>
    );
}

export function CreateSkill({ skills, setSkills }) {
    const options = possibleSkills;
    const [selectedSkills, setSelectedSkills] = useState(skills);

    const handleSkillsChange = (newSkills) => {
        setSelectedSkills(newSkills);
    };

    async function handleAdd(itemToAdd) {
        console.log("itemToAdd:", itemToAdd);
        const user_id = localStorage.getItem("user_id");
        const processedItem = {
            name: itemToAdd,
        };
        console.log("processedItem:", processedItem);

        const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
        const response1 = axiosInterceptor.post(`/create_skill/${user_id}/`, processedItem, {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken,
            },
        });
        const response2 = axiosInterceptor.post(`/create_skill_vec/${user_id}/`, processedItem, {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken,
            },
        });
    }

    async function handleDelete(itemToRemove) {
        console.log("itemtoremove:", itemToRemove);

        const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
        return axiosInterceptor.delete(`/delete_skill/${itemToRemove.id}/`, {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken,
            },
        });
    }

    const handleSave = async () => {
        const addedSkills = selectedSkills.filter(
            (skill) => !skills.includes(skill)
        );
        const removedSkills = skills.filter(
            (skill) => !selectedSkills.includes(skill)
        );

        try {
            const addPromises = addedSkills.map(handleAdd);
            const deletePromises = removedSkills.map(handleDelete);

            await Promise.all([...addPromises, ...deletePromises]);

            setSkills(selectedSkills);
            console.log("All skills updated successfully");
        } catch (error) {
            console.error("Error updating skills:", error);
            // Handle error (e.g., show error message to user)
        }
    };

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <Stack gap={2}>
                <Form.Group className="m-0">
                    <Typeahead
                        id="skill-select"
                        labelKey="name"
                        multiple
                        onChange={handleSkillsChange}
                        options={options}
                        placeholder="Select your skills"
                        selected={selectedSkills}
                        allowNew
                        newSelectionPrefix=""
                    />
                    <Button 
                        onClick={handleSave} 
                        className="ml-auto mr-0 mt-4 float-end" 
                        variant="primary"
                    >
                        <i className="bi bi-floppy mr-2"></i>
                        Save
                    </Button>
                </Form.Group>
            </Stack>
        </Form>
    );
}
