export const backgroundQuestions = [
  { id: 1, question: "What is your name?" },
  { id: 2, question: "Tell us about yourself." },
  { id: 3, question: "Why do you want to work here?" },
  { id: 4, question: "What are your strengths and weaknesses?" },
];

export const behavioralQuestions = [
  { id: 1, question: "What did you study in College?" },
  { id: 2, question: "What is your biggest strength?" },
  { id: 3, question: "Tell me about a time you faced a challenge at work." },
  { id: 4, question: "Describe a situation where you showed leadership." },
  { id: 5, question: "How do you handle stress and pressure?" },
];

export const technicalQuestions = [
  {
    id: 1,
    question: "Find and return the sum of two integers",
    example1: "Input: 1, 2 \n Output: 3",
    example2: "Input: 10, 11 \n Output: 21",
  },
  {
    id: 2,
    question: "Find and return the sum of two integers",
    example1: "Input: 1, 2 \n Output: 3",
    example2: "Input: 10, 11 \n Output: 21",
  },
  {
    id: 3,
    question: "Find and return the sum of two integers",
    example1: "Input: 1, 2 \n Output: 3",
    example2: "Input: 10, 11 \n Output: 21",
  },
  {
    id: 4,
    question: "Find and return the sum of two integers",
    example1: "Input: 1, 2 \n Output: 3",
    example2: "Input: 10, 11 \n Output: 21",
  },
  {
    id: 5,
    question: "Find and return the sum of two integers",
    example1: "Input: 1, 2 \n Output: 3",
    example2: "Input: 10, 11 \n Output: 21",
  },
];
