import { useEffect, useState } from "react";
import ScreenSpinner from "../components/ScreenSpinner";
import axiosInterceptor from "../utils/axiosInterceptor.ts";
import { Button } from "react-bootstrap";

export default function AdminDashboard() {
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [userData, setUserData] = useState([]);

    function load_user_data() {
        console.log("Loading")
        axiosInterceptor.get('load_all_data/');
    }

    const accept = async (user) => {
        await axiosInterceptor.patch(
            `/management/verify_user/${user.user_id}/`
        );
        setUserData(
            userData.filter(
                (existingUser) => existingUser.user_id !== user.user_id
            )
        );
    };

    const reject = async (user) => {
        await axiosInterceptor.delete(
            `/management/reject_user/${user.user_id}/`
        );
        setUserData(
            userData.filter(
                (existingUser) => existingUser.user_id !== user.user_id
            )
        );
    };

    useEffect(() => {
        axiosInterceptor
            .get("/management/get_users_pending_verification/")
            .then((res) => {
                setUserData(
                    res.data.sort((a, b) =>
                        a.full_name.localeCompare(b.full_name)
                    )
                );
                setFinishedLoading(true);
            });
    }, []);

    return !finishedLoading ? (
        <ScreenSpinner />
    ) : (
        <>
            <h1 className="text-2xl font-bold mb-6">
                Pending User Verifications
            </h1>
            <div className="overflow-x-auto">
                <Button
                onClick = {load_user_data()}
                >
                    Load Data into Vectors
                </Button>
                <table className="min-w-full bg-white rounded-lg shadow-lg">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b font-semibold text-black text-center">
                                Index
                            </th>
                            <th className="py-2 px-4 border-b text-left font-semibold text-black">
                                Name
                            </th>
                            <th className="py-2 px-4 border-b text-left font-semibold text-black">
                                Email
                            </th>
                            <th className="py-2 px-4 border-b text-left font-semibold text-black">
                                Resume
                            </th>
                            <th className="py-2 px-4 border-b text-left font-semibold text-black">
                                Joined
                            </th>
                            <th className="py-2 px-4 border-b"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {userData.map((user, index) => (
                            <tr key={index} className="hover:bg-gray-100">
                                <td className="py-2 px-4 border-b text-center">
                                    {index + 1}
                                </td>
                                <td className="py-2 px-4 border-b">
                                    {user.full_name}
                                </td>
                                <td className="py-2 px-4 border-b">
                                    {user.email}
                                </td>
                                <td className="py-2 px-4 border-b">
                                    <a
                                        href={user.resume_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        disabled={!user.resume_link}
                                        className="text-blue-500 hover:underline"
                                    >
                                        View Resume
                                    </a>
                                </td>
                                <td className="py-2 px-4 border-b">
                                    {new Date(user.joined).toLocaleDateString()}
                                </td>
                                <td className="py-2 px-4 border-b text-right flex flex-row gap-2 items-center w-min">
                                    <Button
                                        variant="success"
                                        size="sm"
                                        onClick={() => accept(user)}
                                    >
                                        Accept
                                    </Button>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() => {
                                            if (
                                                window &&
                                                window.confirm(
                                                    `Are you sure you want to reject ${user.full_name}?`
                                                )
                                            ) {
                                                reject(user);
                                            }
                                        }}
                                    >
                                        Reject
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}
