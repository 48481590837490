import React, { useState, useEffect } from "react";
import axiosInterceptor from "../../../utils/axiosInterceptor.ts";

const PDFViewer = ({ userId, documentType }) => {
    const [docs, setDocs] = useState(null);
    const [error, setError] = useState(null);
    const [selectDocIndex, setSelectDocIndex] = useState(0);

    var filepath = `user_career_data/user_id=${userId}/resumes/`
    if (documentType === 'Transcript') {
        filepath = `user_career_data/user_id=${userId}/transcripts/`
    }

    useEffect(() => {
        const fetchDocs = async () => {
            try {
                const response = await axiosInterceptor.get(
                    `/serve_private_doc/${filepath}`
                );
                // console.log("response: ", response.data.private_docs);
                // console.log(response.config.url);
                setDocs(response.data.private_docs);
                // console.log("Docs1: ", docs);
            } catch (err) {
                console.log("Error fetching presigned URL:", err);
                setError("Failed to fetch PDF link");
            }
        };

        fetchDocs();
    }, []);

    return (
        <div className="flex flex-col gap-y-2">
            {docs && docs.length > 0 ? (
                docs.map((doc) => (
                    <a href={doc[0]} target="_blank" rel="noopener noreferrer">
                        {doc[1]}
                    </a>
                ))
            ) : (
                <p className="font-normal text-gray-700">
                    No {documentType.toLowerCase()} uploaded
                </p>
            )}
        </div>
    );
};

export { PDFViewer };
