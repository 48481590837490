import React, { useState, useRef, useEffect } from "react";
import { Button, Container, ProgressBar, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CameraRecorder from "../routes/test_center/CameraRecorder";
import Webcam from "react-webcam";

export default function InterviewTemplate({ type, questions }) {
    const navigate = useNavigate();
    const cameraRecorderRef = useRef(null);
    const [isQuestionsBegun, setIsQuestionsBegun] = useState(false);
    const [questionsAsked, setQuestionsAsked] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
    const numQuestionsToAsk = 3;
    const [showExitModal, setShowExitModal] = useState(false);

    useEffect(() => {
        const shuffledQuestions = questions.sort(() => 0.5 - Math.random());
        setQuestionsAsked(shuffledQuestions.slice(0, numQuestionsToAsk));
    }, [questions]);

    const startInterview = () => {
        setCurrentQuestionIndex(0);
        setIsQuestionsBegun(true);
    };

    const exitPage = () => {
        if (cameraRecorderRef.current) {
            cameraRecorderRef.current.stopAndDownload();
        } else {
            console.log("cameraRecorderRef is null");
        }
        setTimeout(() => navigate("/test-center/home"), 1000);
    };

    const handleExitClick = () => {
        setShowExitModal(true);
    };

    const handleCloseExitModal = () => {
        setShowExitModal(false);
    };

    const handleConfirmExit = () => {
        handleCloseExitModal();
        exitPage();
    };

    const handleNextQuestion = () => {
        if (currentQuestionIndex === questionsAsked.length - 1) {
            setTimeout(
                () => setCurrentQuestionIndex((prevIndex) => prevIndex + 1),
                1000
            );
            setTimeout(exitPage, 2000);
        } else {
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        }
    };

    return (
        // <Container fluid className="bg-light min-vh-100 py-4">
        <Container fluid className="min-vh-100 py-4" style={{ backgroundColor: "#ffffff" }}>

            <div className="mb-3 flex flex-col items-start gap-y-4">
                <h1 className="text-3xl font-medium text-left">{type} Exam</h1>
                <div className="flex flex-row items-center gap-x-3">
                    <ProgressBar
                        now={
                            ((currentQuestionIndex + 1) / numQuestionsToAsk) *
                            100
                        }
                        variant="success"
                        style={{
                            height: "8px",
                            width: "200px",
                            backgroundColor: "#bbb",
                        }}
                    />
                    {/* if questions began, should n/x questions, otherwise show test not started yet */}
                    {isQuestionsBegun ? (
                        <p>
                            <span className="font-semibold">
                                {currentQuestionIndex + 1}/
                                {questionsAsked.length}
                            </span>{" "}
                            questions
                        </p>
                    ) : (
                        <p>Test not started yet</p>
                    )}
                </div>
            </div>
            <div className="bg-white shadow-lg rounded-3xl p-4">
                {isQuestionsBegun ? (
                    currentQuestionIndex < questionsAsked.length ? (
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                            <div className="lg:col-span-2">
                                <h5 className="mb-3 text-lg font-semibold">
                                    Question {currentQuestionIndex + 1}
                                </h5>
                                <p className="mb-4 text-gray-800">
                                    {
                                        questionsAsked[currentQuestionIndex]
                                            .question
                                    }
                                </p>
                                {/* Add input field for answer here if needed */}
                            </div>
                            <div className="lg:col-span-1">
                                <div className="webcam-container">
                                    <CameraRecorder
                                        isBackground={true}
                                        ref={cameraRecorderRef}
                                        ids={questionsAsked.map(
                                            (question) => question.id
                                        )}
                                        type="background"
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="text-center">
                            <p className="text-lg leading-normal tracking-tight py-10">
                                You have completed all questions!
                            </p>
                        </div>
                    )
                ) : (
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                        <div className="prose lg:col-span-2">
                            <h5 className="mb-4 font-semibold text-black">
                                Instructions
                            </h5>
                            <ol className="space-y-2 text-sm text-gray-800 tracking-tight -ml-2">
                                <li className="list-decimal">
                                    Select the types of jobs you are interested
                                    in applying for.
                                </li>
                                <li className="list-decimal">
                                    You will be given a set of{" "}
                                    {type.toLowerCase()} interview questions.
                                    Please answer each question within the time
                                    limit
                                    {type === "Behavioral" &&
                                        " using the STAR response format"}
                                    .
                                </li>
                                <li className="list-decimal">
                                    Submit each answer when you are done with
                                    your response.
                                    <span className="italic ml-1">
                                        (Note: All answers will automatically
                                        submit once the time is up)
                                    </span>
                                </li>
                            </ol>
                        </div>
                        <div className="lg:col-span-1 m-auto lg:mx-0">
                            <div className="webcam-container lg:float-right">
                                <Webcam className="rounded w-full max-w-[300px]" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="py-4 flex flex-col sm:flex-row justify-end gap-3">
                {isQuestionsBegun && (
                    <button
                        className="rounded-full px-4 py-1.5 border-1.5 border-solid border-special-blue text-special-blue font-base text-sm"
                        onClick={handleExitClick}
                    >
                        Exit Test
                    </button>
                )}
                {!isQuestionsBegun ? (
                    <Button
                        onClick={startInterview}
                        variant="primary"
                        className="bg-special-blue border-0 rounded-full text-sm px-4"
                    >
                        Start Interview
                    </Button>
                ) : (
                    currentQuestionIndex < questionsAsked.length && (
                        <Button
                            variant="primary"
                            className="bg-special-blue border-0 rounded-full text-sm px-4"
                            onClick={handleNextQuestion}
                        >
                            {currentQuestionIndex === questionsAsked.length - 1
                                ? "Submit Test"
                                : "Next"}
                        </Button>
                    )
                )}
            </div>

            {/* Exit Confirmation Modal */}
            <Modal show={showExitModal} onHide={handleCloseExitModal} centered>
                <Modal.Body className="p-4">
                    <h4 className="mb-4 text-lg font-medium text-black">
                        Are you sure?
                    </h4>
                    <p className="mb-2 text-black tracking-tight text-sm">
                        Exiting the test will end your interview and cause you
                        to lose all your progress!
                    </p>
                    <div className="d-flex justify-content-center gap-3 float-right">
                        <Button
                            variant="secondary"
                            onClick={handleCloseExitModal}
                            size="sm"
                            className="px-4 py-1 rounded-pill"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleConfirmExit}
                            size="sm"
                            className="px-4 py-1 rounded-pill bg-special-blue border-0"
                        >
                            I'm sure
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </Container>
    );
}
