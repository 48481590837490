import React from "react";
import { behavioralQuestions, backgroundQuestions } from "./QuestionBank";
import InterviewTemplate from "../../templates/InterviewTemplate";

export function Behavioral() {
  return (
    <InterviewTemplate type="Behavioral" questions={behavioralQuestions} />
  );
}

export function Background() {
  return (
    <InterviewTemplate type="Background" questions={backgroundQuestions} />
  );
}
