import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import React, { useEffect, useMemo } from "react";
import axiosInterceptor from "../utils/axiosInterceptor.ts";

function Upload({
  userId,
  content,
  params,
  rootDirectory,
  directoryPath,
  uploadEndpoint,
  processEndpoint,
  // onUploadSuccess,
}) {
  console.log("Upload");
  const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
  const [fileSelected, setFileSelected] = React.useState(false);
  console.log("FILE = ", fileSelected);

  // const survey = useMemo(() => {
  //   // Your survey object initialization logic here
  //   const json = {
  //     "elements": [
  //       {
  //         "type": "file",
  //         "title": "Please upload your files",
  //         "name": "files",
  //         "storeDataAsText": false,
  //         "waitForUpload": true,
  //         "allowMultiple": true,
  //         "maxSize": 104857600,
  //         "hideNumber": true,
  //         "backgroundColor": "#000000"
  //       }
  //     ]
  //   };
  //   const newSurvey = new Model(json); // Example initialization, adjust based on your actual logic
  //   // Any setup or configuration of `newSurvey` goes here
  //   return newSurvey;
  // }, []);

  console.log("upload functionality");

  const json = {
    elements: [
      {
        type: "file",
        title: "Please upload your files",
        name: "files",
        storeDataAsText: false,
        waitForUpload: true,
        allowMultiple: true,
        maxSize: 104857600,
        hideNumber: true,
        backgroundColor: "#000000",
      },
    ],
  };

  const survey = new Model(json);
  var completeButton = survey.navigationBar.actions.find(
    (x) => x.id === "sv-nav-complete"
  );
  survey.navigationBar.actions.splice(
    survey.navigationBar.actions.indexOf(completeButton),
    1
  );
  survey.showCompletedPage = true;

  survey.onComplete.add((sender, options) => {
    console.log("On Complete");
    console.log(JSON.stringify(sender.data, null, 3));

    // setFileSelected(false);
  });

  // survey.onValueChanged.add((sender, options) => {
  //   console.log("Value changed");
  //   if (options.name === "files") {
  //     const hasFiles = options.value && options.value.length > 0;
  //     setFileSelected(hasFiles);
  //   }
  // });

  survey.onUploadFiles.add((_, options) => {
    console.log("File uploaded");
    // console.log(options);
    if (options.name === "files") {
      // console.log(options.value);
      const hasFiles = options.value && options.value.length > 0;
      // setFileSelected(hasFiles);

      const formData = new FormData();
      options.files.forEach((file) => {
        formData.append("file", file);
      });

      console.log(rootDirectory);
      console.log(userId);
      // console.log(directoryPath);
      formData.append("root_directory", `${rootDirectory}`);
      // formData.append("directoryPath", directoryPath);
      const uploadURL = `${uploadEndpoint}${userId}/`; // Assuming uploadEndpoint ends with 'file_upload/'
      console.log(uploadURL);
      axiosInterceptor
        .post(uploadURL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRFToken": csrfToken,
          },
        })
        .then(async (uploadResponse) => {
          console.log("Upload successful", uploadResponse.data);
          survey.doComplete();
          const newDoc = uploadResponse.data.private_doc; // Assuming the response contains the new document info

          // onUploadSuccess(newDoc); // Trigger the callback to update the parent state

          setFileSelected(true);

          // // Trigger processing if a process endpoint is provided
          // if (processEndpoint) {
          //   const processURL = `${processEndpoint}${userId}/`; // Assuming uploadEndpoint ends with 'file_upload/'
          //   const processResponse = await axios.post(
          //     processURL,
          //     {
          //       // Include any data necessary for processing here
          //       // This might vary based on whether it's a resume or a submission
          //     },
          //     {
          //       headers: {
          //         "Content-Type": "application/json",
          //         "X-CSRFToken": csrfToken,
          //       },
          //     }
          //   );

          //   console.log("Processing successful", processResponse.data);
          // }
        })
        .catch((error) => {
          console.error("Error during file upload or processing: ", error);
        });
    }
  });

  useEffect(() => {
    if (fileSelected) {
      survey.doComplete();
      setFileSelected(false);
      window.location.reload();
    }
  }, [fileSelected]);

  // useEffect(() => {
  //   if (fileUploaded) {
  //     survey.doComplete();
  //     setFileSelected();
  //   }
  // }, [fileUploaded]);

  return (
      <Survey model={survey} className="!bg-transparent" />
  );
}

export { Upload };
