import React, { useState } from "react";
import CareerCard from "../../components/profile/career/CareerCard";
import Resume, { CreateRes } from "../../components/profile/career/Resume";
import Transcript, {
    CreateTrans,
} from "../../components/profile/career/Transcript";
import Experience, {
    CreateExp,
} from "../../components/profile/career/Experience";
import Skills, { CreateSkill } from "../../components/profile/career/Skills";
// import { ReactComponent as Edit } from "../../assets/userAssets/images/edit.svg";
import Education, {
    CreateEdu,
} from "../../components/profile/career/Education";
import ExternalCourse, {
    CreateCourse,
} from "../../components/profile/career/ExternalCourses";
import Modal from "react-bootstrap/Modal";
import { PDFViewer } from "../../components/profile/career/DocumentViewer";
// import { Upload } from "../../components/Upload";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";
import { Button } from "react-bootstrap";
// const BLANK_RESUME = {
//   title: "",
//   filepath: "",
// };

const BLANK_USER = [];
const BLANK_SKILLS = [];
const BLANK_EDUCATION = [];
const BLANK_COURSES = [];
const BLANK_EXPERIENCE = [];

const BLANK_RESUME = [];
const BLANK_TRANSCRIPT = [];

export default function CareerProfile() {
    const [userData, setUserData] = useState(BLANK_USER);
    // const [resume, setResume] = useState(BLANK_RESUME);
    const [skills, setSkills] = useState(BLANK_SKILLS);
    const [education, setEducation] = useState(BLANK_EDUCATION);
    const [courses, setCourses] = useState(BLANK_COURSES);
    const [experiences, setExperiences] = useState(BLANK_EXPERIENCE);
    const [resume, setResume] = useState(BLANK_RESUME);
    const [transcript, setTranscript] = useState(BLANK_TRANSCRIPT);

    const [showUploadResumeModal, setShowUploadResumeModel] = useState(false);
    const [showUploadTranscriptModal, setShowUploadTranscriptModel] = useState(
        false
    );

    const saveResumes = async () => {

    }; // TODO: implement
    const saveTranscripts = async () => {

    }; // TODO: implement

    const userId = localStorage.getItem("user_id");
    React.useEffect(() => {
        try {
            axiosInterceptor
                .get(`/getuser/` + userId + "/")
                .then((response) => {
                    setUserData(response.data["u_data"]);
                    setEducation(response.data["ed_data"]);
                    setCourses(response.data["course_data"]);
                    setExperiences(response.data["exp_data"]);
                    const skill_dicts = response.data["skill_data"];
                    setSkills(skill_dicts);
                });
        } catch (error) {
            console.log("error:", error);
        }
    }, [userId]);

    //console.log(education);
    return (
        <div className="flex flex-col gap-y-3 w-full">
            <CareerCard title="Experiences" showEditButton={false}>
                <Experience expList={experiences} />
                <CreateExp
                    expList={experiences}
                    setExperiences={setExperiences}
                />
            </CareerCard>

            <CareerCard title="Skills" showAddButton={false} modalTitle="Manage Skills">
                <Skills skills={skills} />
                <CreateSkill skills={skills} setSkills={setSkills} />
            </CareerCard>

            <CareerCard title="Education" showEditButton={false}>
                <Education eduList={education} />
                <CreateEdu eduList={education} setEducation={setEducation} />
            </CareerCard>

            <CareerCard title="External Courses" showEditButton={false}>
                <ExternalCourse courseList={courses} />
                <CreateCourse
                    courseList={courses}
                    setCourse={setCourses}
                    userEds={education}
                />
            </CareerCard>

            <CareerCard
                title="Resume/CV"
                showAddButton={false}
                showEditAsModal={false}
            >
                {/* NOTE: this is extremely unsafe and should be redone */}
                <PDFViewer
                    userId={userId}
                    documentType={"Resume"}
                />
                <div>
                    <Resume resume={resume} />
                    <CreateRes Resume={resume} userId={userId} />
                    {/* { showUploadResumeModal ? (
                        <CreateRes Resume={resume} userId={userId} />
                    ) : (
                    <div className="flex flex-row float-end gap-x-2 items-center">
                        <Button
                            variant="primary"
                            className="float-right ml-auto mr-0"
                            onClick={() => setShowUploadResumeModel(true)}
                        >
                            <i className="mr-2 bi bi-folder"></i>
                            Upload New
                        </Button>
                        <Button
                            variant="primary"
                            className="float-right ml-auto mr-0"
                            onClick={saveResumes}
                        >
                            <i className="mr-2 bi bi-save"></i>
                            Save
                        </Button>
                    </div>)} */}
                </div>
            </CareerCard>

            <CareerCard
                title="Transcript"
                showAddButton={false}
                showEditAsModal={false}
            >
                <PDFViewer
                    userId={userId}
                    documentType={"Transcript"}
                />
                <div>
                    <Transcript transcript={transcript} />
                    <CreateTrans transcript={transcript} userId={userId} />
                    {/* <div className="flex flex-row float-end gap-x-2 items-center">
                        <Button
                            variant="primary"
                            className="float-right ml-auto mr-0"
                            onClick={() => setShowUploadTranscriptModel(true)}
                        >
                            <i className="mr-2 bi bi-folder"></i>
                            Upload New
                        </Button>
                        <Button
                            variant="primary"
                            className="float-right ml-auto mr-0"
                            onClick={saveTranscripts}
                        >
                            <i className="mr-2 bi bi-save"></i>
                            Save
                        </Button>
                    </div> */}
                </div>
            </CareerCard>
        </div>
    );
}
