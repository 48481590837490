import React, { useState } from "react";
import Modal from "../../Modal";
import { Button } from "react-bootstrap";

export default function CareerCard({
    title,
    children,
    showAddButton = true,
    showEditAsModal = true,
    showEditButton = true,
    modalTitle = title,
}) {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    return (
        <div className="w-full p-4 rounded-lg bg-white flex flex-col gap-y-4">
            <div className="flex flex-row justify-between items-center">
                <h4 className="text-lg font-semibold">{title}</h4>
                <div className="flex flex-row gap-x-2">
                    {showAddButton && (
                        <>
                            <Button
                                variant="light"
                                className="float-right ml-auto mr-0"
                                onClick={() => setShowAddModal(true)}
                            >
                                <i className="mr-2 bi bi-plus-lg"></i>
                                Add
                            </Button>
                            <Modal
                                title={title}
                                show={showAddModal}
                                setShow={setShowAddModal}
                            >
                                {showEditButton ? children[2] : children[1]}
                            </Modal>
                        </>
                    )}
                    {showEditButton && (
                        <Button
                            variant="light"
                            className="float-right ml-auto mr-0"
                            onClick={() => setShowEditModal(true)}
                        >
                            <i className="mr-2 bi bi-pencil-square"></i>
                            Edit
                        </Button>
                    )}
                    {showEditAsModal && (
                        <Modal
                            title={modalTitle}
                            show={showEditModal}
                            setShow={setShowEditModal}
                        >
                            {children[1]}
                        </Modal>
                    )}
                </div>
            </div>
            {children[0]}
            {!showEditAsModal && showEditModal && children[1]}
        </div>
    );
}
