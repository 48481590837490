import React, { useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    ListGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faPlayCircle,
    faCircle,
} from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "./ProgressBar";
import "./CourseOverview.css"; // Custom CSS for additional styling

const lessonData = {
    course: "Introduction To CS in Python",
    progress: 25,
    lessons: [
        {
            name: "Lesson 1",
            completed: true,
            description: "Introduction to basics",
            progress: 45,
            contents: [
                { name: "video 1 (22 Minutes)", progress: 0 },
                { name: "Review Questions", progress: 0 },
            ],
        },
        {
            name: "Lesson 2",
            completed: false,
            description: "For Loops",
            progress: 0,
            contents: [
                { name: "video 1 (22 Minutes)", progress: 0 },
                { name: "Review Questions", progress: 0 },
            ],
        },
        {
            name: "Lesson 3",
            completed: false,
            description: "While Loops",
            progress: 0,
            contents: [
                { name: "video 1 (22 Minutes)", progress: 0 },
                { name: "Review Questions", progress: 0 },
            ],
        },
        {
            name: "Lesson 4",
            completed: false,
            description: "Functions",
            progress: 0,
            contents: [
                { name: "video 1 (22 Minutes)", progress: 0 },
                { name: "Review Questions", progress: 0 },
            ],
        },
    ],
    selectedLesson: {
        name: "Lesson 2",
        parts: [
            { name: "Video 1 (22 minutes)", completed: false },
            { name: "Review Questions", completed: false },
        ],
    },
};

const ClassOverview = () => {
    const [selectedLesson, setSelectedLesson] = useState(lessonData.lessons[0]);

    return (
        <Container fluid className="mt-5">
            <Row className="justify-content-center">
                <Col xs={12} md={5} className="p-4">
                    <div className="box-gradient">
                        <h3>Introduction to CS in Python</h3>
                        <hr />
                        <div className="progress-container">
                            <ListGroup>
                                {lessonData.lessons.map((lesson, index) => (
                                    <ListGroup.Item
                                        key={index}
                                        className="d-flex align-items-center course-item"
                                        action
                                        onClick={() =>
                                            setSelectedLesson(lesson)
                                        }
                                        active={
                                            selectedLesson.name === lesson.name
                                        }
                                    >
                                        <div
                                            className={`circle ${
                                                lesson.completed
                                                    ? "completed"
                                                    : ""
                                            }`}
                                        >{lesson.completed && <FontAwesomeIcon icon={faCheckCircle} />}
                                        </div>
                                        <div className="course-name">
                                            {lesson.name}
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="me-2">Course Progress:</div>
                        <ProgressBar completed={25}/>
                    </div>
                </Col>
                <Col xs={12} md={5} className="p-4">
                    <div className="box-gradient">
                        <h3>{`${selectedLesson.name}: ${selectedLesson.description}`}</h3>
                        <hr />
                        <div className="progress-container">
                            <ListGroup>
                                {selectedLesson.contents.map((content, index) => (
                                    <ListGroup.Item
                                        key={index}
                                        className="d-flex align-items-center course-item"
                                        action
                                        active={
                                            selectedLesson.name === content.name
                                        }
                                    >
                                        <div
                                            className={`circle ${
                                                content.completed
                                                    ? "completed"
                                                    : ""
                                            }`}
                                        >
                                            {content.completed && (
                                                <i className="bi bi-check"></i>
                                            )}
                                        </div>
                                        <div className="course-name">
                                            {content.name}
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="me-2">Lesson Progress:</div>
                        <ProgressBar completed={45}/>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ClassOverview;
