import React from "react";
import { useState } from "react";
import Avatar from "boring-avatars";
import { Button, Modal } from "react-bootstrap";
import EditProfile from "./EditProfile"

export default function ProfileCard({ user, user_data }) {
    const [showEdit, setShowEdit] = useState(false);
    console.log(user)

    const { UserID, name, email, phone, website, linkedin } = user;

    const handleCloseEdit = () => setShowEdit(false); // Function to close the modal
    const handleShowEdit = () => setShowEdit(true); // Function to show the modal

    // TODO: university is not sent from the backend.
    const university = "Cornell University";

    return (
            <div className="flex flex-col w-full gap-y-3 p-6 rounded-lg shadow-card bg-white">
                <div className="w-2/3 mx-auto mb-4">
                    <Avatar
                        name={UserID}
                        variant="beam"
                        size="100%"
                        colors={["#99CBFF", "#EDEDED"]}
                        className="mx-auto mb-4"
                    />
                </div>
                <h4 className="text-2xl font-medium">{name}</h4>
                <p className="text-sm font-medium -mt-3 mb-2">{university}</p>
                <div className="flex flex-col gap-y-0.5">
                    <label className="text-xs font-medium text-gray-500">
                        Email
                    </label>
                    <p className="text-lg font-medium text-black">{email}</p>
                </div>
                <div className="flex flex-col gap-y-0.5">
                    <label className="text-xs font-medium text-gray-500">
                        Phone
                    </label>
                    <p className="text-lg font-medium text-black">{phone}</p>
                </div>
                <div className="flex flex-col gap-y-0.5">
                    <label className="text-xs font-medium text-gray-500">
                        Website
                    </label>
                    <p className="text-lg font-medium text-black">{website}</p>
                </div>
                <div className="flex flex-col gap-y-0.5">
                    <label className="text-xs font-medium text-gray-500">
                        LinkedIn
                    </label>
                    <p className="text-lg font-medium text-black">{linkedin}</p>
                </div>
                <Button
                    variant="light"
                    className="float-right ml-auto mr-0"
                    onClick={() => handleShowEdit()}
                >
                    <i className="mr-2 bi bi-pencil-square"></i>
                    Edit
                </Button>
                <Modal show={showEdit} onHide={handleCloseEdit} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditProfile existingInfo={user_data}/>
                </Modal.Body>
                </Modal>
            </div>
                );
            }
