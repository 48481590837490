import React, { useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

// import { Resources, PdfIframe, Video } from './LectureTabs';
import { Resources, Discussion, PdfIframe, Video } from "./LectureTabs";

import { Upload } from "../../Upload.jsx";

// Function to calculate directory path for course submissions
// const getDirectoryPathForSubmissions = (content, userId) => {
//   const pathParts = content.mainFilesList[0].split('/');
//   pathParts.splice(0, 3); // Adjust based on your directory structure
//   pathParts.pop(); // Remove the file name
//   return pathParts.join('/');
// };

export default function Lecture({ content }) {
    const s3BaseUrl =
        "https://ai-academy-storage-f951b86a124629-staging.s3.amazonaws.com/";
    console.log("content");
    console.log(content);

    // Ensure the file path is correctly encoded to handle special characters
    // This replaces spaces with %20, plus signs with %2B, etc.
    const encodedFilePath = encodeURIComponent(
        content.mainFilesList[0]
    ).replace(/%2F/g, "/");

    // Construct the full URL
    const url = `${s3BaseUrl}${encodedFilePath}`;
    // const url = `${s3BaseUrl}/${content.mainFilesList[0].replace(/^\/static\//, '')}`;
    // console.log('content')
    // console.log(content)
    // const url = `${s3BaseUrl}` + content.mainFilesList[0]

    console.log("url");
    console.log(url);
    const params = useParams();
    const location = useLocation();
    const tabNames = content.tabs.map((tab) => tab.name);

    // Inside your component
    const getCurrentTab = useCallback(() => {
        const currentPath = location.pathname.split("/").pop();
        return (
            tabNames.find((tab) => tab.toLowerCase() === currentPath) ||
            tabNames[0]
        );
        // Function logic here
    }, [location.pathname, tabNames]); // Include all dependencies here

    // useEffect hook that uses getCurrentTab
    // useEffect(() => {
    //   // Logic that uses getCurrentTab
    // }, [getCurrentTab]); // Now depends on the memoized version of getCurrentTab

    // const getCurrentTab = () => {
    //   const currentPath = location.pathname.split('/').pop();
    //   return tabNames.find(tab => tab.toLowerCase() === currentPath) || tabNames[0];
    // };

    const [activeTab, setActiveTab] = useState(getCurrentTab());
    useEffect(() => {
        setActiveTab(getCurrentTab());
    }, [getCurrentTab]);

    return (
        <div className="mx-auto my-auto items-center justify-center w-full h-full">
            <div className="tab overflow-auto w-full h-full">
                {activeTab === "Content" && content.type === "pdf" && (
                    <div className="p-2 lg:p-10">
                        <PdfIframe pdfUrl={url} />
                    </div>
                )}
                {activeTab === "Content" && content.type === "video" && (
                    <Video videoUrl={url} />
                )}
                {activeTab === "Resources" && (
                    <div className="p-2 lg:p-10">
                        {" "}
                        <Resources content={content} mainDownloadURL={url} />{" "}
                    </div>
                )}
                {/* {activeTab === "Upload" && (
                    <div className="p-10">
                        <Upload
                            content={content}
                            params={params}
                            rootDirectory="technical_submissions"
                            directoryPath={
                                content.mainFilesList[0].replace(
                                    "public/static/",
                                    ""
                                ) + "/"
                            }
                            uploadEndpoint={`file_upload/`}
                            processEndpoint={`process_and_rate_submissions/`}
                        />
                    </div>
                )} */}
                {/* {activeTab === "Discussion" && (
                    <div className="p-10">
                        <Discussion content={content} />
                    </div>
                )} */}
            </div>
        </div>
    );
}

export { Lecture };
