import React from "react";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { Upload } from "../../Upload";

export default function Transcript({ transcript }) {
  return (
    <a className="transcript-link one-line" href="https://google.com">
      {transcript.title}
    </a>
  );
}

export function CreateTrans({
  transcript,
  onUploadReady,
  onUploadSuccess,
  isUploadReady,
  userId,
}) {
  function handleSubmit(e) {
    //Prevent Reload
    e.preventDefault();

    // Read the form data
    const formData = new FormData();
    formData.append("transcripts", transcript);
  }

  return (
    <>
      <Form>
        <Stack gap={2}>
          <Upload
            content={transcript}
            params={{}}
            rootDirectory="transcripts"
            directoryPath=""
            uploadEndpoint={`file_upload/`}
            // processEndpoint={`process_resumes/`}
            onUploadSuccess={onUploadSuccess} // Pass the prop to Upload
            userId={userId}
          />
        </Stack>
      </Form>
    </>
  );
}
