import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Container, Modal } from "react-bootstrap";
import CameraRecorder from "./CameraRecorder";
import CodingBox from "./CodingBox";
import Timer from "./Timer";

function Technical() {
    const location = useLocation();
    const navigate = useNavigate();
    const cameraRecorderRef = useRef(null);
    const [showExitModal, setShowExitModal] = useState(false);

    const dummyQuestions = [
        {
            type: "other",
            question:
                "We will examine your knowledge of data structures and algorithms. Please answer the following questions:",
            optionalImages: [
                <img
                    src="https://placehold.co/600x400"
                    alt="placeholder"
                    className="w-full"
                />,
            ],
            parts: [
                {
                    label: "a",
                    text: "Define a stack and its main operations:",
                    inputType: "text",
                    answer: "",
                },
                {
                    label: "b",
                    text:
                        "Which of the following is NOT a characteristic of a queue?",
                    inputType: "multipleChoice",
                    options: [
                        "First-In-First-Out (FIFO)",
                        "Last-In-First-Out (LIFO)",
                        "Enqueue operation",
                        "Dequeue operation",
                    ],
                    answer: "",
                },
                {
                    label: "c",
                    text:
                        "Which data structure would be most suitable for implementing an 'undo' functionality?",
                    inputType: "multipleChoice",
                    options: ["Array", "Queue", "Stack", "Linked List"],
                    answer: "",
                },
                {
                    label: "d",
                    text: "Provide an example use case for a queue:",
                    inputType: "text",
                    answer: "",
                },
            ],
        },
        {
            type: "coding",
            question: "Write a function to reverse a string in place.",
            example1: "Input: 'hello'\nOutput: 'olleh'",
            example2: "Input: 'OpenAI'\nOutput: 'IAnepO'",
        },
        {
            type: "coding",
            question:
                "Implement a function to find the maximum subarray sum in an array of integers.",
            example1:
                "Input: [-2, 1, -3, 4, -1, 2, 1, -5, 4]\nOutput: 6 (subarray [4, -1, 2, 1])",
            example2:
                "Input: [1, 2, 3, -2, 5]\nOutput: 9 (subarray [1, 2, 3, -2, 5])",
        },
    ];
    const dummyLeetcodes = [0, 1, 1];
    const dummyTestCases = [
        {
            input: [1, 2, 3],
            target: 6,
        },
        {
            input: [4, 5, 6],
            target: 15,
        },
    ];

    const interviewData = location.state;
    const [questions, setQuestions] = useState(dummyQuestions);
    const [leetcodes, setLeetcodes] = useState(dummyLeetcodes);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [testCases, setTestCases] = useState(dummyTestCases);
    const [currentTestCase, setCurrentTestCase] = useState(0);
    const [answers, setAnswers] = useState({});

    useEffect(() => {
        if (interviewData) {
            setQuestions(interviewData["Questions"]);
            setLeetcodes(interviewData["Leetcodes"]);
        }
    }, [interviewData]);

    const exitPage = () => {
        if (cameraRecorderRef.current) {
            cameraRecorderRef.current.stopAndDownload();
        } else {
            console.log("cameraRecorderRef is null");
        }
        setTimeout(() => navigate("/test-center/home"), 1000);
    };

    const handleExitClick = () => {
        setShowExitModal(true);
    };

    const handleCloseExitModal = () => {
        setShowExitModal(false);
    };

    const handleConfirmExit = () => {
        handleCloseExitModal();
        exitPage();
    };

    const handleNextQuestion = () => {
        if (currentQuestionIndex === questions.length - 1) {
            setTimeout(
                () => setCurrentQuestionIndex((prevIndex) => prevIndex + 1),
                1000
            );
            setTimeout(exitPage, 2000);
        } else {
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        }
    };

    const handleTimeUp = () => {
        exitPage();
    };

    const handleAnswerChange = (questionIndex, partLabel, value) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionIndex]: {
                ...prevAnswers[questionIndex],
                [partLabel]: value,
            },
        }));
    };

    const createExampleCase = (example, index) => (
        <div className="example-case mb-3" key={index}>
            <h6 className="font-weight-bold">Example {index + 1}:</h6>
            <pre className="bg-light p-2 rounded mt-2">{example}</pre>
        </div>
    );

    const renderQuestion = () => {
        const currentQuestion = questions[currentQuestionIndex];

        if (currentQuestion.type === "coding") {
            return (
                <>
                    <p className="mb-4 text-gray-800">
                        {currentQuestion.question}
                    </p>
                    <div className="examples-box">
                        {createExampleCase(currentQuestion.example1, 0)}
                        {currentQuestion.example2 &&
                            createExampleCase(currentQuestion.example2, 1)}
                    </div>
                </>
            );
        } else if (currentQuestion.type === "other") {
            return (
                <div className="flex flex-col gap-4">
                    <p className="text-gray-800">{currentQuestion.question}</p>
                    {currentQuestion?.optionalImages?.map((image, index) => (
                        <div key={index} className="w-full">
                            {image}
                        </div>
                    ))}
                </div>
            );
        }
    };

    return (
        // <Container fluid className="bg-light py-4 position-relative">
        <Container
            fluid
            className="min-vh-100 py-4 relative"
            style={{ backgroundColor: "#ffffff" }}
        >
            <div
                className="position-absolute -top-2 end-0 m-3"
                style={{ zIndex: 1000 }}
            >
                <div
                    className="position-relative !rounded-full"
                    style={{ width: "150px", height: "150px" }}
                >
                    <CameraRecorder
                        isTechnical={true}
                        rounded
                        moreClasses="!w-full !h-full"
                        ref={cameraRecorderRef}
                        ids={Array.from(
                            { length: questions.length },
                            (_, index) => index + 1
                        )}
                        type="technical"
                    />
                    <div className="position-absolute top-[6.5rem] right-[3rem]">
                        <Timer onTimeUp={handleTimeUp} circular={true} tiny />
                    </div>
                </div>
            </div>

            <h1 className="text-3xl font-medium mb-4">Technical Exam</h1>

            <div className="row bg-white shadow-lg rounded-3xl p-4">
                <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                    <div>
                        <h5 className="mb-3 text-lg font-semibold">
                            Question {currentQuestionIndex + 1}
                        </h5>
                        {renderQuestion()}
                    </div>
                </div>

                <div className="col-12 col-lg-8">
                    {questions[currentQuestionIndex].type === "coding" ? (
                        <CodingBox
                            setParentTestCases={setTestCases}
                            parentCurrentTestCase={currentTestCase}
                            setParentCurrentTestCase={setCurrentTestCase}
                        />
                    ) : (
                        <div className="prose border rounded-xl p-4 border-3 !border-[#6782B4] bg-[#DEE2E6] float-right w-full font-medium text-black tracking-tighter">
                            {questions[currentQuestionIndex].parts.map(
                                (part, index) => (
                                    <div key={index} className="mb-3">
                                        <label className="text-sm mb-2">
                                            {part.label}. {part.text}
                                        </label>
                                        {part.inputType === "text" ? (
                                            <textarea
                                                className="form-control text-sm"
                                                rows="3"
                                                value={
                                                    answers[
                                                        currentQuestionIndex
                                                    ]?.[part.label] || ""
                                                }
                                                onChange={(e) =>
                                                    handleAnswerChange(
                                                        currentQuestionIndex,
                                                        part.label,
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Type your answer here..."
                                            />
                                        ) : (
                                            <div className="grid grid-flow-row gap-2 text-sm">
                                                {part.options.map(
                                                    (option, optionIndex) => (
                                                        <button
                                                            key={optionIndex}
                                                            className={`rounded-xl text-left w-1/2 flex justify-start items-center py-1 px-3 ${
                                                                answers[
                                                                    currentQuestionIndex
                                                                ]?.[
                                                                    part.label
                                                                ] === option
                                                                    ? "bg-[#90b8f6]"
                                                                    : "bg-white"
                                                            }`}
                                                            style={{
                                                                border:
                                                                    answers[
                                                                        currentQuestionIndex
                                                                    ]?.[
                                                                        part
                                                                            .label
                                                                    ] === option
                                                                        ? "1px solid #999"
                                                                        : "1px solid #bbb",
                                                            }}
                                                            onClick={() =>
                                                                handleAnswerChange(
                                                                    currentQuestionIndex,
                                                                    part.label,
                                                                    option
                                                                )
                                                            }
                                                        >
                                                            {option}
                                                        </button>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div className="py-4 d-flex justify-content-end gap-3">
                <Button
                    variant="outline-primary"
                    className="rounded-pill px-4 py-2"
                    onClick={handleExitClick}
                >
                    Exit Test
                </Button>
                {currentQuestionIndex < questions.length && (
                    <Button
                        variant="primary"
                        className="bg-special-blue border-0 rounded-pill px-4 py-2"
                        onClick={handleNextQuestion}
                    >
                        {currentQuestionIndex === questions.length - 1
                            ? "Submit Test"
                            : "Next"}
                    </Button>
                )}
            </div>

            {/* Exit Confirmation Modal */}
            <Modal show={showExitModal} onHide={handleCloseExitModal} centered>
                <Modal.Body className="p-4">
                    <h4 className="mb-4 text-lg font-medium text-black">
                        Are you sure?
                    </h4>
                    <p className="mb-2 text-black tracking-tight text-sm">
                        Exiting the test will end your interview and cause you
                        to lose all your progress!
                    </p>
                    <div className="d-flex justify-content-center gap-3 float-right">
                        <Button
                            variant="secondary"
                            onClick={handleCloseExitModal}
                            size="sm"
                            className="px-4 py-1 rounded-pill"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleConfirmExit}
                            size="sm"
                            className="px-4 py-1 rounded-pill bg-special-blue border-0"
                        >
                            I'm sure
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </Container>
    );
}

export default Technical;
