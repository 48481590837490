import { LineChart, Line } from "recharts";

import {
    ChartContainer,
    ChartLegend,
    ChartLegendContent,
    ChartTooltip,
    ChartTooltipContent,
} from "../components/ui/chart";

export default function ProgressLines({
    chartData,
    chartConfig,
    showLegend = true,
}) {
    return (
        <ChartContainer config={chartConfig} className="!h-60 !w-full !py-10 !px-10">
            {/* FIXME: some custom styling stuff because it is forcibly preserving the aspect ratio — cleanup later. */}
            <LineChart
                accessibilityLayer
                data={chartData}
            >
                <ChartTooltip content={<ChartTooltipContent hideLabel />} />
                {showLegend && (
                    <ChartLegend
                        className="!flex !flex-col !gap-x-0 !ml-12 !mt-4"
                        content={<ChartLegendContent />}
                        layout="vertical"
                        verticalAlign="middle"
                        align="right"
                    />
                )}
                {Object.keys(chartConfig).map((key) => {
                    return (
                        <Line
                            dataKey={key}
                            key={key}
                            fill={`var(--color-${key})`}
                            color={`var(--color-${key})`}
                            stroke={`var(--color-${key})`}
                            strokeWidth={3}
                            type="monotone"
                        />
                    );
                })}
            </LineChart>
        </ChartContainer>
    );
}
