import {
    Database,
    Brain,
    Cpu,
    Code,
    Calculator,
    ChartBar,
    DollarSign,
    Network,
    HardDrive,
    Microscope,
    Lightbulb,
    Binary,
    TrendingUp,
  } from 'lucide-react';
  
  const paths = [
    {
      name: "Computer Foundations",
      courses: [
        { name: "Introduction to Computer Science & Programming in Python", courseid: "6.0001" },
        { name: "Programming in Java", courseid: "6.092" },
        { name: "Programming in C", courseid: "6.087/6.088" },
        { name: "Programming in C++", courseid: "6.096-2" },
        { name: "Algorithms", courseid: "6.006" },
        { name: "Software Construction", courseid: "6.005" },
        { name: "Computation Structures", courseid: "6.004" },
        { name: "Math for Computer Science", courseid: "6.042J" }
      ],
      icon: Cpu,
    },
    {
      name: "Math Foundations",
      courses: [
        { name: "Single Variable Calculus", courseid: "18.01" },
        { name: "Multivariable Calculus", courseid: "18.02" },
        { name: "Differential Equations", courseid: "18.03" },
        { name: "Linear Algebra", courseid: "18.06" },
        { name: "Real Analysis", courseid: "18.100A" },
        { name: "Numerical Analysis", courseid: "18.330" },
        { name: "Numerical Methods", courseid: "18.335J" }
      ],
      icon: Calculator,
    },
    {
      name: "Data Foundations",
      courses: [
        { name: "Computational Thinking & Data Science", courseid: "6.0002" },
        { name: "Probability & Random Variables", courseid: "18.600" },
        { name: "Fundamentals of Probability", courseid: "6.436J" },
        { name: "Statistics for Applications", courseid: "18.650" },
        { name: "Statistical Thinking and Data Analysis", courseid: "15.075J" },
        { name: "Mathematical Statistics", courseid: "18.655" }
      ],
      icon: ChartBar,
    },
    {
      name: "Software Engineer",
      courses: [
        { name: "Design and Analysis of Algorithms", courseid: "6.046J" },
        { name: "Software Studio", courseid: "6.170" },
        { name: "Database Systems", courseid: "6.830" },
        { name: "Performance Engineering of Software Systems", courseid: "6.172" },
        { name: "Principles of Computer Systems", courseid: "6.826" },
        { name: "Leetcode Patterns", courseid: "1.01" }
      ],
      icon: Code,
    },
    {
      name: "Network Engineer",
      courses: [
        { name: "Computer Networks", courseid: "6.829" },
        { name: "Network and Computer Security", courseid: "6.857" },
        { name: "Data Communication Networks", courseid: "6.263J" },
        { name: "Computer Systems Engineering", courseid: "6.033" },
        { name: "Distributed Computer Systems Engineering", courseid: "6.824" },
        { name: "Distributed Algorithms", courseid: "6.852J" }
      ],
      icon: Network,
    },
    {
      name: "Hardware Engineer",
      courses: [
        { name: "Computer System Architecture", courseid: "6.823" },
        { name: "Parallel and Heterogeneous Computer Architecture", courseid: "6.888" },
        { name: "Operating System Engineering", courseid: "6.828" },
        { name: "Computer Graphics", courseid: "6.837" },
        { name: "Multicore Programming Primer", courseid: "6.189" },
        { name: "Computer Systems Security", courseid: "6.858" }
      ],
      icon: HardDrive,
    },
    {
      name: "Data Scientist",
      courses: [
        { name: "Topics in Mathematics of Data Science", courseid: "18.S096" },
        { name: "Algorithms for Inference", courseid: "6.438" },
        { name: "High Dimensional Statistics", courseid: "18.S997" },
        { name: "Stochastic Processes", courseid: "18.445" },
        { name: "Discrete Stochastic Processes", courseid: "6.262" },
        { name: "Advanced Stochastic Processes", courseid: "15.070J" },
        { name: "Theory of Probability", courseid: "18.175" }
      ],
      icon: Database,
    },
    {
      name: "Machine Learning Engineer",
      courses: [
        { name: "Machine Learning I", courseid: "6.867" },
        { name: "Machine Learning II", courseid: "6.036" },
        { name: "Natural Language Processing", courseid: "6.864" },
        { name: "Algorithmic Aspects of Machine Learning", courseid: "18.409" },
        { name: "Matrix Methods in Data Analysis, Signal Processing, and Machine Learning", courseid: "18.065" },
        { name: "Machine Vision", courseid: "6.801" }
      ],
      icon: Brain,
    },
    {
      name: "Biotechnologist",
      courses: [
        { name: "Machine Learning for Healthcare", courseid: "6.S897" },
        { name: "Computational Biology", courseid: "6.047" },
        { name: "Algorithms for Computational Biology", courseid: "6.096" },
        { name: "Foundations of Computational and Systems Biology", courseid: "7.91J" },
        { name: "Computational Functional Genomics", courseid: "7.90J" },
        { name: "Computation for Biological Engineers", courseid: "20.181" }
      ],
      icon: Microscope,
    },
    {
      name: "Artificial Intelligence Research",
      courses: [
        { name: "Artificial Intelligence", courseid: "6.034" },
        { name: "Statistical Learning Theory", courseid: "9.520" },
        { name: "Statistical Learning Theory II", courseid: "18.465" },
        { name: "Mathematics of Machine Learning", courseid: "18.657" }
      ],
      icon: Lightbulb,
    },
    {
      name: "Computation Research",
      courses: [
        { name: "Advanced Algorithms", courseid: "6.854J" },
        { name: "Theory of Computation", courseid: "18.404J" },
        { name: "Automata, Computability & Complexity Theory", courseid: "6.045J" },
        { name: "Advanced Complexity Theory", courseid: "18.405J" }
      ],
      icon: Binary,
    },
    {
      name: "Quantitative Trading",
      courses: [
        { name: "Finance Theory I", courseid: "15.401" },
        { name: "Finance Theory II", courseid: "15.402" },
        { name: "Analytics of Finance", courseid: "15.450" },
        { name: "Topics in Math with Applications in Finance", courseid: "18.S096-2" },
        { name: "Statistical Methods in Economics", courseid: "14.381" },
        { name: "Econometrics", courseid: "14.382" },
        { name: "Dynamic Programming & Stochastic Control", courseid: "6.231" }
      ],
      icon: TrendingUp,
    },
    {
      name: "Quantitative Research",
      courses: [
        { name: "Nonlinear Econometric Analysis", courseid: "14.385" },
        { name: "Optimization Methods", courseid: "15.093J" },
        { name: "Nonlinear Programming", courseid: "15.084J" },
        { name: "Integer Programming & Combinatorial Optimization", courseid: "15.083J" },
        { name: "Combinatorial Optimization", courseid: "18.433" },
        { name: "Network Optimization", courseid: "15.082J" },
        { name: "Convex Optimization", courseid: "6.079" },
        { name: "Convex Analysis & Optimization", courseid: "6.253" },
        { name: "Algebraic Techniques & Semidefinite Optimization", courseid: "6.972" }
      ],
      icon: DollarSign,
    },
  ];
  
  export default paths;