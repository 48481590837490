import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";

import {
    ChartContainer,
    ChartLegend,
    ChartLegendContent,
    ChartTooltip,
    ChartTooltipContent,
} from "../components/ui/chart";

export default function StackedBar({
    chartData,
    chartConfig,
    xKey,
    showLegend=true,
}) {
    return (
        <ChartContainer config={chartConfig}>
            <BarChart accessibilityLayer data={chartData} className="!py-2 lg:!py-0">
                <CartesianGrid vertical={false} />
                <YAxis
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                    tickCount={3}
                />
                <XAxis
                    dataKey={xKey}
                    tickLine={false}
                    tickMargin={10}
                    axisLine={false}
                    tickFormatter={(value) => value.slice(0, 3)}
                />
                <ChartTooltip content={<ChartTooltipContent hideLabel />} />
                {showLegend && (
                    <ChartLegend
                        content={<ChartLegendContent />}
                        verticalAlign="top"
                        layout="horizontal"
                        align="center"
                        className="!mx-auto !pl-10"
                    />
                )}
                {Object.keys(chartConfig).map((key) => {
                    return (
                        <Bar
                            dataKey={key}
                            stackId="a"
                            className="h-full"
                            key={key}
                            fill={`var(--color-${key})`}
                            markerWidth={4}
                            strokeWidth={3}
                            barSize={6}
                        />
                    );
                })}
            </BarChart>
        </ChartContainer>
    );
}
