import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Lectures() {
  console.log('in Lectures.js')
  const navigate = useNavigate();
//   const { courseName } = useParams(); // Assuming 'courseName' is part of your route params
  const location = useLocation();

  const goToNextLecture = () => {
    console.log('trying to go to next lecture')
    // Regex to match /number or /number/word at the end of the URL
    const urlPattern = /\/(\d+)(\/\w+)?$/;
    const match = location.pathname.match(urlPattern);

    if (match) {
      const currentLectureNumber = parseInt(match[1], 10);
      const additionalPathSegment = match[2]; // This will be '/word' if present

      // Calculate the next lecture number
      const nextLectureNumber = currentLectureNumber + 1;

      // Construct the new URL
      // If there was an additional path segment (i.e., /word), we replace the whole /number/word with /newNumber
      // Otherwise, just replace /number with /newNumber
      let newPath;
      if (additionalPathSegment) {
        newPath = location.pathname.replace(urlPattern, `/${nextLectureNumber}`);
      } else {
        newPath = location.pathname.replace(urlPattern, `/${nextLectureNumber}${additionalPathSegment}`);
      }

      // Navigate to the new URL
      navigate(newPath);
    } else {
      console.error("The current URL doesn't match the expected pattern.");
    }
  };

  return (
    <div>
      <button onClick={goToNextLecture}>Go to Next Lecture</button>
    </div>
  );
}


// import React from "react";
// import { Navigate } from "react-router-dom";

// export default function Lectures() {
//   const cur_lec = 0;
//   return <Navigate to={cur_lec.toString()} replace={true} />;
// }
