import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";

const TraditionalWorkflowHome = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [courses, setCourses] = useState([
        { name: 'Introduction To CS in Python', completed: true },
        { name: 'Introduction To EECS', completed: false },
        { name: 'Mathematics for CS (Discrete)', completed: false },
        { name: 'Programming in Java', completed: false },
    ]);
    const [loading, setLoading] = useState(true);

    const fetchCourses = async () => {
        try {
            const response = await axiosInterceptor.get('/api/courses');
            setCourses(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching courses:', error);
            setLoading(false);
        }
    };

    const handleNavigate = (path) => {
        navigate(path);
    };

    useEffect(() => {
        fetchCourses();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="homepage">
                <div className="slider-area slider-height2 d-flex align-items-center">
                    <div className="col-xl-12 hero-cap hero-cap2 text-center">
                        <h2>Traditional Workflow</h2>
                        <div
                            className="recommender-section"
                            style={{ marginTop: "0px", textAlign: "center" }}
                        >
                            <p style={{ color: "white", fontSize: "18px" }}>
                                Explore the courses available in the traditional workflow.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Container>
                <Row className="mt-5">
                    {courses.map((course, index) => (
                        <Col md={4} className="mb-4" key={index}>
                            <Card>
                                <Card.Img variant="top" src="https://via.placeholder.com/300x200" />
                                <Card.Body className="text-center">
                                    <Card.Title>{course.name}</Card.Title>
                                    <Button
                                        variant="primary"
                                        onClick={() => handleNavigate('/course-recommender/course-overview')}
                                    >
                                        View Course
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    );
};

export default TraditionalWorkflowHome;
